import React from 'react';
import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChangePasswordForm from '../../forms/ChangePasswordForm';

const ChangePassword = () => {
	const { loginInfo, loading } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {}, []);
	return (
		<div>
			<Box mt={3}>
				{/* <h2>Change Password</h2> */}
				<Divider style={{ marginTop: 20 }} />
				<ChangePasswordForm />
			</Box>
		</div>
	);
};

export default ChangePassword;
