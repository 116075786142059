import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const EaseBuzzFormBus = ({ pgCharge }) => {
	const location = useLocation();
	const { depart, arrival } = useSelector((state) => state.ui);
	console.log('location', location);
	return (
		<>
			<form
				action='https://www.marinersforex.com/pg/easebuzz/request.php'
				method='POST'>
				<Grid container alignItems='center' style={{}}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='txnid'>OrderID</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input name='mrctTxtID' id='txnid' value={location.state.orderId} />
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='amount'>Amount</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='amount'
							id='amount'
							value={`${Number(
								Math.round(
									Number(
										location.state.seat &&
											location.state.seat?.length > 0 &&
											location.state.seat
												?.map((item) => parseInt(item.totalFare))
												.reduce((prev, next) => prev + next)
									) + Number(pgCharge)
								)
							)}.00`}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='surl'>returnURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='returnURL'
							id='surl'
							value='https://www.marinersforex.com/paymentpageBus'
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{}}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='furl'>failedURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='failedURL'
							id='furl'
							value='https://www.marinersforex.com/failed'
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='phone'>Mobile</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='phone'
							id='phone'
							value={location.state.passengerData.phone}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='email'>Email</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='email'
							id='email'
							value={location.state.passengerData.email}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='firstname'>Name</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='firstname'
							id='firstname'
							value={`${location.state.passengerData.adult[0].firstname} ${location.state.passengerData.adult[0].lastname}`}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='productinfo'>Productinfo</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input name='productinfo' id='productinfo' value='Bus Booking' />
					</Grid>
				</Grid>

				<div style={{ marginTop: 30, textAlign: 'center' }}>
					<button
						style={{
							cursor: 'pointer',
							background: 'green',
							padding: 10,
							color: 'white',
							borderRadius: 5,
						}}>
						Proceed to Payment{' '}
					</button>
				</div>
			</form>
		</>
	);
};

export default EaseBuzzFormBus;
