import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../components/resuableComponents/Footer';
import { Padding } from '@mui/icons-material';
import { busBlockDispatch } from '../reducers/BusReducer';
function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const PaymentPageBus = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const [load, setLoading] = useState(false);
	const navigate = useNavigate();
	const { formDataBus } = useSelector((state) => state.ui);
	const { loading } = useSelector((state) => state.bus);
	useEffect(() => {
		if (query.get('status') === 'success') {
			dispatch(busBlockDispatch(formDataBus, navigate));
		}
	}, []);
	return (
		<>
			<Navbar />
			<div>
				<div>
					{loading ? (
						<>
							<Box
								style={{
									textAlign: 'center',

									paddingTop: 50,
								}}>
								<div style={{ fontSize: 35, textAlign: 'center' }}>
									Don't Refresh This Page
								</div>
								<div style={{ fontSize: 35, textAlign: 'center' }}>
									Booking is UnderProcess Please Wait.....
								</div>
								<div
									style={{
										textAlign: 'center',
										display: 'flex',
										justifyContent: 'center',
									}}>
									<img
										style={{ height: 150, marginTop: 20 }}
										src={
											'https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif'
										}
									/>
								</div>
							</Box>
						</>
					) : (
						<>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								!!Ticket might have been issued.!!
							</div>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								Please Contact Your Supplier
							</div>
						</>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default PaymentPageBus;
