import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Box, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { agentedituserDispatch } from '../reducers/AgentReducer';

const ProfileForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { agentInfo } = useSelector((state) => state.agent);
	const handleFormSubmit = (data) => {
		let formData = {
			userId:
				agentInfo &&
				agentInfo.result &&
				agentInfo.result.result &&
				agentInfo.result.result.id,
			userFirstName: data.name,
			userLastName: data.name,
			userPhoneCode: '91',
			userPhone: data.phone_no,
			agencyName: data.agencyName,
		};
		dispatch(agentedituserDispatch(formData));
	};
	return (
		<Formik
			initialValues={{
				name: agentInfo?.result?.result?.name,
				email: agentInfo?.result?.result?.email,
				phone_no: agentInfo?.result?.result?.phone,
				agencyName: agentInfo?.result?.result?.agencyName,
			}}
			onSubmit={handleFormSubmit}>
			{(formik) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box style={{ padding: 20 }}>
							<Box
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px',
									padding: 20,
									background: 'white',
								}}>
								<Box display={'flex'} justifyContent={'space-between'}>
									<h2>Basic Informations</h2>
									<h2>
										{/* {`Wallet Amount: ₹ ${loginInfo?.result?.walletAmount}`} */}
									</h2>
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Name</span>
									</Grid>
									<Grid item xs={12} lg={8}>
										<Field
											name='name'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.name
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.name ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.name}
											</span>
										) : null}
									</Grid>
								</Grid>
								<Grid container alignItems={'center'} style={{ marginTop: 15 }}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Email</span>
									</Grid>
									<Grid item xs={12} lg={8}>
										<Field
											name='email'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
								</Grid>

								<Grid container alignItems={'center'} style={{ marginTop: 15 }}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Mobile</span>
									</Grid>
									<Grid item xs={12} lg={8}>
										<Field
											name='phone_no'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.phone_no
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.phone_no ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.phone_no}
											</span>
										) : null}
									</Grid>
								</Grid>
								<Grid container alignItems={'center'} style={{ marginTop: 15 }}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Agency Name</span>
									</Grid>
									<Grid item xs={12} lg={8}>
										<Field
											name='agencyName'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.agencyName
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.agencyName ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.agencyName}
											</span>
										) : null}
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}></Grid>
									<Grid item xs={3}></Grid>
									<Grid item xs={12} lg={3}>
										<Button
											type='submit'
											style={{
												background: 'rgb(25, 118, 210)',
												color: 'white',
												marginTop: 30,
												// padding: 16,
												borderRadius: 5,
												width: '100%',
											}}>
											Save Changes
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ProfileForm;
