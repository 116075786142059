import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showFootermenuDispatch } from '../reducers/HomeReducer';

const data1 = [
	{
		ques: 'Do I need to confirm my tour package?',
		ans: 'There is no need to confirm your tour package. If you still feel you would like to verify that your package was made, you can  contact our customer services team.',
	},
	{
		ques: 'Is it necessary to register for booking a tour package?',
		ans: 'No, it is not necessary. But, if you register with Our Website, it would be beneficial for you.',
	},
	{
		ques: 'Can I book international tour package through Our Website?',
		ans: 'Yes, we provide international tour packages as well.',
	},
	{
		ques: 'How can I pay the booking amount for tour package?',
		ans: 'You can pay through Debit/Credit Card or net banking.',
	},
	{
		ques: 'How can I cancel my tour package booking?',
		ans: 'You can cancel your tour package after logging to our website and go to the section cancellation.',
	},
	{
		ques: 'How much refund I will get on cancelling a tour package?',
		ans: 'It depends on the number of days before which your tour was to commence. ',
	},
	{
		ques: 'How I can get the refund amount of cancellation?',
		ans: 'You can get the refund amount at the mode though which you have paid the amount.',
	},
	{
		ques: 'What are the meals and types of meals offered with tour and packages?',
		ans: 'The meal and type of meals depend on the type of hotel that you will get. You can see the description of each day. ',
	},
	{
		ques: 'What are the cancellation charges for cancelling a tour package?',
		ans: 'The following are the cancellation charges will depend on the type of tour packages and the tour package provider. You can refer to terms and conditions and cancellation policy while booking your tour package.',
	},
	{
		ques: 'How do I know that tour package, which I selected was booked?',
		ans: 'You will receive an SMS and email on confirmation of your tour package booking.',
	},
];

const FAQ = () => {
	const dispatch = useDispatch();
	const { footerMenu } = useSelector((state) => state.home);
	let cont = [];
	if (footerMenu?.result?.length > 0) {
		footerMenu?.result?.map((i) => {
			if (i.id === '186') {
				cont.push({
					...i,
				});
			}
		});
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(showFootermenuDispatch());
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					FAQ
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<div
							style={{ marginTop: 20 }}
							dangerouslySetInnerHTML={{
								__html: cont[0]?.content,
							}}
						/>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default FAQ;
