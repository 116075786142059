import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { setOnward, setReturn } from '../reducers/UiReducer';
import { addSearchFlightDispatch } from '../reducers/TBOReducer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import HeaderMobileWeb from '../components/resuableComponents/Navbar/HeaderMobileWeb';
import FlightRoundTripRow from '../components/resuableComponents/FlightRoundTripRow';
import FlightRoundTripRowReturn from '../components/resuableComponents/FlightRoundTripRowReturn';
import FlightDomRountrip from '../components/resuableComponents/FlightDomRountrip';
import FlightRowRoundtripSkeleton from '../components/resuableComponents/FlightRowRoundtripSkeleton';
import FilterRoundtripSection from '../components/resuableComponents/FilterRoundtripSection';
import ModifySearch from '../components/resuableComponents/ModifySearch';

const FlightResultRoundtrip = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { tbo, loading } = useSelector((state) => state.tbo);
	const { onwardFlight, returnFlight } = useSelector((state) => state.ui);
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const [rule, SetRule] = useState(false);
	const [rule2, SetRule2] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.state) {
			dispatch(addSearchFlightDispatch(location.state));
			dispatch(setOnward(null));
			dispatch(setReturn(null));
			setResult([]);
			setResult2([]);
		}
	}, [location.state]);

	return (
		<>
			<Navbar />
			<MNavbar />
			{matches ? (
				<HeaderMobileWeb active='3' setResult={setResult} SetRule={SetRule} />
			) : (
				''
			)}
			{matches ? '' : <ModifySearch />}
			<Box style={{ padding: matches ? '0px' : '20px 90px' }}>
				<Grid container spacing={matches ? '' : 4}>
					<Grid item xs={3}>
						{matches ? (
							''
						) : (
							<FilterRoundtripSection
								setResult={setResult}
								SetRule={SetRule}
								SetRule2={SetRule2}
								setResult2={setResult2}
							/>
						)}
					</Grid>
					<Grid item xs={12} lg={9}>
						{matches ? (
							<>
								<Box
									display='flex'
									justifyContent={'space-between'}
									style={{ padding: 20 }}>
									<Box>
										<h1
											style={{
												fontWeight: 'bold',
											}}>{`${location.state.Segments[0].Origin}-${location.state.Segments[0].Destination}`}</h1>
										<h1 style={{ fontWeight: 'bold', fontSize: 12 }}>
											{location.state.Segments[0].PreferredDepartureTime}
										</h1>
									</Box>

									<h1 style={{ fontWeight: 'bold' }}>Modify</h1>
								</Box>
							</>
						) : (
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								style={{
									background: 'rgba(241, 250, 239, 1)',
									padding: 15,
									borderRadius: 12,
								}}>
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>Sort by:</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box
										style={{
											fontWeight: 'bold',
											color: 'rgba(243, 106, 0, 1)',
										}}>
										{/* Departure */}
									</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Fastest */}</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Price */}</Box>
								)}
								{loading ? (
									<Skeleton animation='wave' width={50} />
								) : (
									<Box style={{ fontWeight: 'bold' }}>{/* Recommanded */}</Box>
								)}
							</Box>
						)}
						{!loading ? (
							<>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										{rule ? (
											result?.length > 0 ? (
												result?.length > 0 &&
												result.map((item) => {
													return <FlightRoundTripRow item={item} />;
												})
											) : (
												<p
													style={{
														marginTop: 20,
														textAlign: 'center',
														fontSize: 16,
													}}>
													No Flight Found
												</p>
											)
										) : (
											tbo?.result?.Response?.Results[0]?.length > 0 &&
											tbo?.result?.Response?.Results[0]?.map((item) => {
												return <FlightRoundTripRow item={item} />;
											})
										)}
									</Grid>
									<Grid item xs={6}>
										{rule2 ? (
											result2?.length > 0 ? (
												result2?.length > 0 &&
												result2.map((item) => {
													return <FlightRoundTripRowReturn item={item} />;
												})
											) : (
												<p
													style={{
														marginTop: 20,
														textAlign: 'center',
														fontSize: 16,
													}}>
													No Flight Found
												</p>
											)
										) : (
											tbo?.result?.Response?.Results[1]?.length > 0 &&
											tbo?.result?.Response?.Results[1]?.map((item) => {
												return <FlightRoundTripRowReturn item={item} />;
											})
										)}
									</Grid>
								</Grid>
							</>
						) : (
							<Grid container spacing={2}>
								<Grid item xs={6}>
									{Array.from(Array(10)).map((i) => {
										return <FlightRowRoundtripSkeleton />;
									})}
								</Grid>
								<Grid item xs={6}>
									{Array.from(Array(10)).map((i) => {
										return <FlightRowRoundtripSkeleton />;
									})}
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
			{onwardFlight && returnFlight ? (
				<>
					<Box
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							right: 0,
							padding: '10px 0',
							background: 'black',
							color: '#bbb',
							zIndex: 9,
						}}>
						<FlightDomRountrip
							onwardFlight={onwardFlight}
							returnFlight={returnFlight}
						/>
					</Box>
				</>
			) : (
				''
			)}

			<Footer />
		</>
	);
};

export default FlightResultRoundtrip;
