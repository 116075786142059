import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agenttransactionhistoryDispatch } from '../../reducers/AgentReducer';
import TranTable from './TransTable';
const TransactionHistory = () => {
	const dispatch = useDispatch();
	const { agentInfo } = useSelector((state) => state.agent);
	useEffect(() => {
		dispatch(agenttransactionhistoryDispatch(agentInfo?.result?.result?.id));
	}, []);
	return (
		<>
			<Box mt={3} ml={3}>
				<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>
					Transaction Details
				</h1>
				<TranTable />
			</Box>
		</>
	);
};

export default TransactionHistory;
