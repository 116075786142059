import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { agentSignupDispatch } from '../reducers/AgentReducer';
const SignUpSchema = Yup.object().shape({
	name: Yup.string().required('name required'),
	email: Yup.string().required('email required'),
	phone: Yup.string().required('phone required'),
	password: Yup.string().required('password required'),
});
const RegisterForm = ({ SetStatus, handleClose }) => {
	const dispatch = useDispatch();
	const handleFormSubmit = (data) => {
		let form = {
			userTitle: 'Mr',
			userFirstName: data.name,
			userLastName: ' ',
			agencyPanCard: ' ',
			userPhone: data.phone,
			userEmail: data.email,
			password: data.password,
			confirmpassword: data.password,
		};
		dispatch(agentSignupDispatch(form, handleClose));
		// alert(JSON.stringify(form));
	};
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Formik
			initialValues={{
				email: '',
				phone: '',
				name: '',
			}}
			validationSchema={SignUpSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;

				return (
					<Form>
						<Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid
											container
											style={{ padding: 10 }}
											spacing={matches ? 2 : 4}>
											<Grid item xs={12} lg={6}>
												<span
													style={{
														color: 'rgba(8, 30, 42, 1)',
														fontWeight: 'bold',
													}}>
													Name
												</span>
												<Field
													name='name'
													type='text'
													placeHolder='Enter Your Name*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.name
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.name ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.name}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} lg={6}>
												<span
													style={{
														color: 'rgba(8, 30, 42, 1)',
														fontWeight: 'bold',
													}}>
													Email
												</span>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your email*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} lg={6}>
												<span
													style={{
														color: 'rgba(8, 30, 42, 1)',
														fontWeight: 'bold',
													}}>
													Phone
												</span>
												<Field
													name='phone'
													type='number'
													placeHolder='Enter Your Number*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.phone
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.phone ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.phone}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} lg={6}>
												<Box display={'flex'} justifyContent={'space-between'}>
													<span
														style={{
															color: 'rgba(8, 30, 42, 1)',
															fontWeight: 'bold',
														}}>
														Create password
													</span>
												</Box>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} lg={6}>
												<Box display={'flex'} justifyContent={'space-between'}>
													<span
														style={{
															color: 'rgba(8, 30, 42, 1)',
															fontWeight: 'bold',
														}}>
														Agency Name
													</span>
												</Box>
												<Field
													name='agencyName'
													type='text'
													placeHolder='Enter Your Agency Name'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.agencyName
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.agencyName ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.agencyName}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} lg={6}>
												<Box display={'flex'} justifyContent={'space-between'}>
													<span
														style={{
															color: 'rgba(8, 30, 42, 1)',
															fontWeight: 'bold',
														}}>
														Phone
													</span>
												</Box>
												<Field
													name='phone'
													type='number'
													placeHolder='Enter Your Phone'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.phone
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.phone ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.phone}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12} style={{}}>
												<Button
													type='submit'
													style={{
														backgroundColor: 'rgb(12, 163, 153)',
														color: 'white',
														width: '100%',
														padding: 10,
													}}>
													Sign up
												</Button>
												<p style={{ fontSize: 12, marginTop: 15 }}>
													{/* By signing up, you agree to Customers.ai’s Terms Of
													Service and Privacy Policy */}
												</p>
												<Box display={'flex'} alignItems={'center'} mt={2}>
													<p style={{ fontSize: 12 }}>
														Already have an account?
													</p>
													<Button
														onClick={() => SetStatus('login')}
														style={{
															backgroundColor: 'rgb(12, 163, 153)',
															color: 'white',
															textTransform: 'capitalize',
															marginLeft: 10,
															padding: '4px 20px',
															fontSize: 12,
														}}>
														Login
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default RegisterForm;
