import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import logo from '../assets/images/logo.png';

const InternationalAirlinePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					International Airline
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<p>
							Check the PNR status and Web check-in for top international
							airlines at MarinersForex.com Choose from a number of different
							fares and compare them to find the best deal in online.
						</p>
						<br />

						<table style={{ width: '100%', marginTop: 20 }}>
							<tbody>
								{Array.from(Array(20)).map((i) => {
									return (
										<>
											<tr>
												<td style={{ padding: 10 }}>Air India</td>
												<td
													style={{
														textAlign: 'center',
														padding: 10,
													}}>
													{' '}
													PNR Status on Air India
												</td>
												<td
													style={{
														textAlign: 'center',
														padding: 10,
													}}>
													Flights Web Check-In Air India
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
						<br />
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default InternationalAirlinePage;
