const { createSlice } = require('@reduxjs/toolkit');
export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};
const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		trip: sessionStorage.getItem('trip')
			? JSON.parse(sessionStorage.getItem('trip'))
			: 'oneway',
		depart: sessionStorage.getItem('depart')
			? JSON.parse(sessionStorage.getItem('depart'))
			: null,
		arrival: sessionStorage.getItem('arrival')
			? JSON.parse(sessionStorage.getItem('arrival'))
			: null,
		headerTab: 'home',
		hoteldes: sessionStorage.getItem('hoteldes')
			? JSON.parse(sessionStorage.getItem('hoteldes'))
			: null,
		room: [1],
		adultH: [2, 2, 2, 2],
		childH: [0, 0, 0, 0],
		childAge1: [],
		childAge2: [],
		childAge3: [],
		childAge4: [],
		paymentToggle: false,
		onwardToggle: null,
		returnToggle: null,
		onwardFlight: null,
		returnFlight: null,
		paymentToggleeasebuzz: null,
		formData: sessionStorage.getItem('formData')
			? JSON.parse(sessionStorage.getItem('formData'))
			: null,
		bookingDataOneway: sessionStorage.getItem('bookingDataOneway')
			? JSON.parse(sessionStorage.getItem('bookingDataOneway'))
			: null,
		addBookingData: sessionStorage.getItem('addBookingData')
			? JSON.parse(sessionStorage.getItem('addBookingData'))
			: null,
		bookingDataONWARD: sessionStorage.getItem('bookingDataONWARD')
			? JSON.parse(sessionStorage.getItem('bookingDataONWARD'))
			: null,
		bookingDataRETURN: sessionStorage.getItem('bookingDataRETURN')
			? JSON.parse(sessionStorage.getItem('bookingDataRETURN'))
			: null,
		selectedBus: sessionStorage.getItem('selectedBus')
			? JSON.parse(sessionStorage.getItem('selectedBus'))
			: null,
		selectedDesBus: sessionStorage.getItem('selectedDesBus')
			? JSON.parse(sessionStorage.getItem('selectedDesBus'))
			: null,
		busId: null,
		busSeat: [],
		busTab: null,
		boardingPoint: null,
		dropingPoint: null,
		paymentToggleBusOffline: false,
		paymentToggleeasebuzzBus: false,
		formDataBus: sessionStorage.getItem('formDataBus')
			? JSON.parse(sessionStorage.getItem('formDataBus'))
			: null,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		setTrip(state, action) {
			state.trip = action.payload;
		},
		setSelectedDepart(state, action) {
			state.depart = action.payload;
			storeData('depart', action.payload);
		},
		setSelectedArrival(state, action) {
			state.arrival = action.payload;
			storeData('arrival', action.payload);
		},
		setSelectedHeaderTab(state, action) {
			state.headerTab = action.payload;
			storeData('headerTab', action.payload);
		},
		setSelectedHotel(state, action) {
			state.hoteldes = action.payload;
			storeData('hoteldes', action.payload);
		},
		setSelectedRoom(state, action) {
			if (state.room.length < 4) {
				state.room = [...state.room, action.payload];
			}
		},
		removeSelectedRoom(state, action) {
			if (state.room.length === 1) {
			} else {
				let remove = state.room;
				remove.pop();
				state.room = remove;
			}
		},
		setAdultH(state, action) {
			state.adultH[action.payload.split('A')[1]] = parseInt(
				action.payload.split('A')[0]
			);
		},
		setChildH(state, action) {
			state.childH[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge1(state, action) {
			state.childAge1[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge2(state, action) {
			state.childAge2[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge3(state, action) {
			state.childAge3[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setChildAge4(state, action) {
			state.childAge4[action.payload.split('C')[1]] = parseInt(
				action.payload.split('C')[0]
			);
		},
		setModalPay(state, action) {
			state.paymentToggle = action.payload;
		},
		setOnwardRow(state, action) {
			state.onwardToggle = action.payload;
		},
		setReturnRow(state, action) {
			state.returnToggle = action.payload;
		},
		setOnward(state, action) {
			state.onwardFlight = action.payload;
		},
		setReturn(state, action) {
			state.returnFlight = action.payload;
		},
		setModalEasebuzz(state, action) {
			state.paymentToggleeasebuzz = action.payload;
		},
		setModalEasebuzzBus(state, action) {
			state.paymentToggleeasebuzzBus = action.payload;
		},
		setFormData(state, action) {
			state.formData = action.payload;
			storeData('formData', action.payload);
		},
		setBookingOnewayData(state, action) {
			state.bookingDataOneway = action.payload;
			storeData('bookingDataOneway', action.payload);
		},
		setAddBookingData(state, action) {
			state.addBookingData = action.payload;
			storeData('addBookingData', action.payload);
		},
		setBookingONWARDData(state, action) {
			state.bookingDataONWARD = action.payload;
			storeData('bookingDataONWARD', action.payload);
		},
		setBookingRETURNData(state, action) {
			state.bookingDataRETURN = action.payload;
			storeData('bookingDataRETURN', action.payload);
		},
		setSelectedBus(state, action) {
			state.selectedBus = action.payload;
			storeData('selectedBus', action.payload);
		},
		setSelectedDesBus(state, action) {
			state.selectedDesBus = action.payload;
			storeData('selectedDesBus', action.payload);
		},
		setBusId(state, action) {
			state.busId = action.payload;
			storeData('busId', action.payload);
		},
		setSelectedSeat(state, action) {
			if (action.payload === null) {
				state.busSeat = [];
			} else {
				if (state.busSeat.length < 6) {
					state.busSeat = [...state.busSeat, action.payload];
				}
			}
		},
		removeSelectedSeatRow(state, action) {
			let filteredRow = state.busSeat.filter(
				(row) => row.name !== action.payload.name
			);
			state.busSeat = filteredRow;
		},
		setBusTab(state, action) {
			state.busTab = action.payload;
		},
		setBoardingPoint(state, action) {
			state.boardingPoint = action.payload;
		},
		setDropingPoint(state, action) {
			state.dropingPoint = action.payload;
		},
		setModalPayBusOffline(state, action) {
			state.paymentToggleBusOffline = action.payload;
		},
		setFormDataBus(state, action) {
			state.formDataBus = action.payload;
			storeData('formDataBus', action.payload);
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	setSelectedDepart,
	setOnwardRow,
	setReturnRow,
	setSelectedArrival,
	setSelectedHeaderTab,
	setSelectedHotel,
	setAdultH,
	setChildH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
	setSelectedRoom,
	removeSelectedRoom,
	setModalPay,
	setOnward,
	setReturn,
	setModalEasebuzz,
	setFormData,
	setBookingOnewayData,
	setAddBookingData,
	setBookingONWARDData,
	setBookingRETURNData,
	setTrip,
	setSelectedBus,
	setSelectedDesBus,
	setBusId,
	setSelectedSeat,
	removeSelectedSeatRow,
	setBusTab,
	setBoardingPoint,
	setDropingPoint,
	setModalPayBusOffline,
	setModalEasebuzzBus,
	setFormDataBus,
} = actions;

export default UiReducer;
