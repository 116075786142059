import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalEasebuzz } from '../../reducers/UiReducer';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import EaseBuzzForm from '../../forms/EaseBuzzForm';
const PaymentEaseBuzz = ({ pay, pgCharge }) => {
	const [loading, setLoading] = useState(false);
	const { paymentToggleeasebuzz } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const handleOk = () => {
		// setLoading(true);
		// handleBook(setLoading);
	};
	const handleCancel = () => {
		dispatch(setModalEasebuzz(false));
	};

	return (
		<>
			<Modal
				open={paymentToggleeasebuzz}
				title='Confirm Booking'
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel} disabled={loading}>
						Cancel
					</Button>,
				]}>
				<Divider />
				<EaseBuzzForm pay={pay} pgCharge={pgCharge} />
			</Modal>
		</>
	);
};
export default PaymentEaseBuzz;
