import React, { useEffect } from 'react';
import {
	Grid,
	Container,
	Box,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import locat from '../../assets/images/locat.svg';
import star from '../../assets/images/star.svg';
import { Divider } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showtourpackageDispatch } from '../../reducers/HomeReducer';
const Package = () => {
	const [data, setData] = useState();
	const { tourpackage } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleChange = (val) => {
		setData(val);
	};
	useEffect(() => {
		dispatch(showtourpackageDispatch());
	}, []);
	let filterCategory = [];
	let filter = [];
	if (tourpackage?.result?.length > 0) {
		filterCategory = [
			...new Set(
				tourpackage &&
					tourpackage?.result &&
					tourpackage?.result?.length > 0 &&
					tourpackage?.result?.map((item) => item.destination)
			),
		];
	}
	useEffect(() => {
		if (filterCategory.length > 0) {
			setData(filterCategory[0]);
		}
	}, [tourpackage]);
	if (tourpackage?.result?.length > 0) {
		tourpackage &&
			tourpackage?.result &&
			tourpackage?.result?.length > 0 &&
			tourpackage?.result?.map((i) => {
				if (i.destination === data) {
					filter.push({
						...i,
					});
				}
			});
	}
	// console.log('filter', filter);
	return (
		<>
			<div
				style={{
					marginTop: 100,
					backgroundImage: `url(${'https://marinersforex.com/photo/bac.svg'}`,
				}}>
				{' '}
				<p
					style={{
						textAlign: 'center',
						color: 'rgb(12 163 153)',
						fontWeight: 'bold',
					}}>
					Explore the world
				</p>
				<Container style={{ marginTop: 20 }}>
					<Grid container>
						<Grid item xs={3}></Grid>
						<Grid item xs={6}>
							<h1
								style={{
									textAlign: 'center',
									color: 'rgba(8, 30, 42, 1)',
									fontSize: 48,
								}}>
								Amazing Featured Tour Package the world
							</h1>
						</Grid>
						<Grid item xs={3}></Grid>
					</Grid>
				</Container>
				<Container style={{ marginTop: 20 }}>
					<Box
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}
						style={{ textAlign: 'center' }}>
						<Box></Box>
						<Box
							display={'flex'}
							style={{
								overflowX: matches ? 'auto' : '',
								overflowY: matches ? 'hidden' : '',
								whiteSpace: matches ? 'nowrap' : '',
								padding: matches ? '20px' : '',
							}}>
							{/* <div
								style={{
									position: 'absolute',
									marginTop: '25px',
									marginLeft:
										data === 'one'
											? '25px'
											: data === 'two'
											? '133px'
											: data === 'three'
											? '245px'
											: data === 'four'
											? '355px'
											: data === 'five'
											? '465px'
											: '',
									// background: '#F7921E',
									color: 'white',
									padding: 5,
								}}>
								<div
									style={{
										width: 0,
										height: 0,
										borderLeft: '20px solid transparent',
										borderRight: '20px solid transparent',
										borderTop: '20px solid rgb(12 163 153)',
									}}></div>
							</div> */}
							{filterCategory.map((i, index) => {
								return (
									<Box
										onClick={() => handleChange(i)}
										style={{
											background:
												data === i
													? 'rgb(12 163 153)'
													: 'rgba(255, 255, 255, 1)',
											color: data === i ? 'white' : '',
											boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
											padding: '10px 20px',
											textAlign: 'center',
											fontSize: 12,
											fontWeight: 'bold',
											borderRadius: 5,
											cursor: 'pointer',
										}}>
										{i}
									</Box>
								);
							})}
						</Box>
						<Box></Box>
					</Box>
				</Container>
				<Container style={{ marginTop: 40 }}>
					<Grid container spacing={3}>
						{filter?.map((i) => {
							return (
								<Grid item xs={12} md={6} lg={3}>
									<Box>
										<img
											src={i.imgpath}
											style={{
												width: '100%',
												borderTopLeftRadius: 5,
												borderTopRightRadius: 5,
												height: 190,
											}}
										/>
										<div
											style={{
												position: 'absolute',
												marginTop: '-15px',
												marginLeft: matches ? '246px' : '170px',
												background: 'rgba(255, 255, 255, 1)',
												color: 'rgba(8, 30, 42, 1)',
												padding: '5px 15px',
												boxShadow: '0px 9px 6px 0px rgba(0, 0, 0, 0.05)',
												fontSize: 12,
												borderRadius: 5,
											}}>
											Bestsellers
										</div>
										<div
											style={{
												position: 'absolute',
												marginTop: matches ? '-237px' : '-182px',
												marginLeft: '10px',
												background: 'rgba(20, 176, 195, 1)',
												color: 'white',
												padding: '5px 15px',
												fontSize: 10,
												borderRadius: 2,
											}}>
											{i.subtitle}
										</div>

										<Box
											style={{
												boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
												padding: 10,
												borderRadius: 5,
											}}>
											<Box display={'flex'}>
												<img src={locat} />
												<span
													style={{
														color: 'rgba(131, 130, 127, 1)',
														fontSize: 12,
														marginLeft: 8,
													}}>
													{i.location}
												</span>
											</Box>
											<h2
												style={{
													color: 'rgba(8, 30, 42, 1)',
													fontSize: 14,
													fontWeight: 'bold',
													marginTop: 10,
												}}>
												{i.title}
											</h2>
											<Box display={'flex'} mt={1} alignItems={'center'}>
												{Array.from(Array(parseInt(i.review))).map(() => {
													return (
														<img src={star} style={{ width: 15, height: 15 }} />
													);
												})}

												<span
													style={{
														color: 'rgba(92, 114, 125, 1)',
														fontSize: 11,
														marginLeft: 5,
													}}>
													({i.review} Review)
												</span>
											</Box>
											<Divider
												style={{ backgroundColor: 'rgba(235, 235, 235, 1)' }}
											/>
											<Box>
												<span style={{ color: 'rgba(131, 130, 127, 1)' }}>
													From
												</span>
												<span
													style={{
														color: 'rgb(12 163 153)',
														fontWeight: 'bold',
														marginLeft: 5,
													}}>
													₹ {i.price}
												</span>
											</Box>
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid>
					<Box textAlign={'center'} mt={4}>
						<Button
							style={{
								background: 'rgb(12 163 153)',
								fontSize: 10,
								color: 'white',
								padding: '15px 25px',
							}}>
							View all tour
						</Button>
					</Box>
				</Container>
			</div>
		</>
	);
};

export default Package;
