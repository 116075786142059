import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalPayBusOffline } from '../../reducers/UiReducer';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
const PaymentVerifyBus = ({ handleBook }) => {
	const [loading, setLoading] = useState(false);
	const { paymentToggleBusOffline } = useSelector((state) => state.ui);
	const location = useLocation();
	const dispatch = useDispatch();
	const handleOk = () => {
		setLoading(true);
		handleBook();
	};
	const handleCancel = () => {
		dispatch(setModalPayBusOffline(false));
	};
	console.log('location', location);
	return (
		<>
			<Modal
				open={paymentToggleBusOffline}
				title='Confirm Booking'
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel} disabled={loading}>
						Cancel
					</Button>,
					<Button
						key='submit'
						type='primary'
						loading={loading}
						onClick={handleOk}>
						Procced
					</Button>,
				]}>
				<Divider />
				<Box display='flex' justifyContent='space-around'>
					<p>ORDER ID: </p>
					<span style={{ fontWeight: 'bold' }}>{location.state.orderId}</span>
				</Box>
				<Box display='flex' justifyContent='space-around' mt={2} mb={3}>
					<p>PAYABLE AMOUNT:</p>
					<span>
						<span style={{ fontWeight: 'bold' }}>
							₹
							{Number(
								location.state.seat &&
									location.state?.seat
										?.map((item) => parseInt(item.totalFare))
										.reduce((prev, next) => prev + next)
							)}
						</span>
					</span>
				</Box>
			</Modal>
		</>
	);
};
export default PaymentVerifyBus;
