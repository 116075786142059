import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const FlightRowRoundtripSkeleton = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<>
			<Box
				mt={2}
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
				}}>
				<Box
					display={'flex'}
					style={{ padding: 25 }}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Box display={'flex'} alignItems={'center'}>
						<Skeleton
							animation='wave'
							variant='rounded'
							width={50}
							height={50}
						/>
						<Box ml={2}>
							<p
								style={{
									fontWeight: 'bold',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									width: '95px',
									height: '1.2em',
									whiteSpace: 'nowrap',
								}}>
								<Skeleton animation='wave' />
							</p>
							<p style={{ fontSize: 12 }}>
								<Skeleton animation='wave' />
							</p>
						</Box>
					</Box>
					<Box>
						<Skeleton animation='wave' width={50} />
						<Skeleton animation='wave' width={50} />
					</Box>
					{matches ? (
						''
					) : (
						<>
							<Box textAlign={'center'}>
								<p style={{ fontSize: 14 }}>
									<Skeleton animation='wave' width={50} />
								</p>
								<p style={{ fontSize: 14 }}>
									<Skeleton animation='wave' width={50} />
								</p>
							</Box>
							{/* <Box>
								<p
									style={{
										
										fontWeight: 'bold',
									}}>
									<Skeleton animation='wave' width={50} />
								</p>
								<p style={{ fontFamily: 'Outfit' }}>
									<Skeleton animation='wave' width={50} />
								</p>
							</Box> */}
							<Box>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 18,
									}}>
									<Skeleton animation='wave' width={50} />
								</p>
								<p style={{ fontSize: 12 }}>
									<Skeleton animation='wave' width={50} />
								</p>
							</Box>
						</>
					)}

					<Skeleton
						animation='wave'
						variant='rounded'
						width={100}
						height={40}
					/>
				</Box>

				<Box
					style={{
						backgroundColor: '#F8F8F8',
						padding: '10px 25px',
						cursor: 'pointer',
					}}>
					<Skeleton animation='wave' width={'100px'} />
				</Box>
			</Box>
		</>
	);
};

export default FlightRowRoundtripSkeleton;
