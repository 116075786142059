import React from 'react';
import { Container, Grid, Box, Button } from '@mui/material';
import Line from '../../assets/images/Line.svg';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const FlightDomRountrip = ({ onwardFlight, returnFlight }) => {
	const { tbo, orderId } = useSelector((state) => state.tbo);
	const navigate = useNavigate();
	const location = useLocation();
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	return (
		<>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Box display={'flex'} alignItems={'center'}>
							<img
								src={`https://nitish.musafirbazar.com/static/media/${
									onwardFlight.Supplier === 'TBO'
										? onwardFlight.AirlineCode
										: ''
								}.gif`}
								style={{ borderRadius: 5, height: 25 }}
							/>
							<Box ml={1}>
								<p
									title={
										onwardFlight.Supplier === 'TBO'
											? onwardFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.AirlineName;
											  })
											: ''
									}
									style={{
										fontWeight: 'bold',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
										width: '50px',
										height: '1.2em',
										whiteSpace: 'nowrap',
										fontSize: 10,
									}}>
									{onwardFlight.Supplier === 'TBO'
										? onwardFlight.Segments[0].slice(0, 1).map((i) => {
												return i.Airline.AirlineName;
										  })
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{`${
										onwardFlight.Supplier === 'TBO'
											? onwardFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.AirlineCode;
											  })
											: ''
									}-${
										onwardFlight.Supplier === 'TBO'
											? onwardFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.FlightNumber;
											  })
											: ''
									}`}
								</p>
							</Box>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{onwardFlight.Supplier
										? onwardFlight.Segments[0].slice(0, 1).map((i) => {
												return i.Origin.DepTime.substr(11, 5);
										  })
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{onwardFlight.Supplier === 'TBO'
										? onwardFlight.FareRules[0].Origin
										: ''}
								</p>
							</Box>
							<Box textAlign={'center'} alignItems={'center'}>
								<p style={{ fontSize: 10 }}>
									{timeConvert(
										onwardFlight.Supplier === 'TBO'
											? onwardFlight.Segments[0].length == 2
												? timedifference(
														onwardFlight.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														onwardFlight.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: onwardFlight.Segments[0].length == 1
												? onwardFlight.Segments[0].map((i) =>
														timedifference(
															onwardFlight.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Origin.DepTime;
																}),
															onwardFlight.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Destination.ArrTime;
																})
														)
												  )
												: onwardFlight.Segments[0].length == 3
												? timedifference(
														onwardFlight.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														onwardFlight.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: ''
											: ''
									)}
								</p>
								<img src={Line} style={{ width: '50%', marginLeft: 40 }} />
								<p style={{ fontSize: 10 }}>
									{onwardFlight.Supplier === 'TBO'
										? onwardFlight.Segments[0].length === 1
											? 'Non-Stop'
											: onwardFlight.Segments[0].length === 2
											? '1 Stop'
											: onwardFlight.Segments[0].length === 3
											? '2 Stop'
											: ''
										: ''}
								</p>
							</Box>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{onwardFlight.Supplier === 'TBO'
										? onwardFlight.Segments[0].length == 3
											? onwardFlight.Segments[0]
													.filter((i, index) => index === 2)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: onwardFlight.Segments[0].length == 2
											? onwardFlight.Segments[0]
													.filter((i, index) => index === 1)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: onwardFlight.Segments[0].length == 1
											? onwardFlight.Segments[0]
													.filter((i, index) => index === 0)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: ''
										: ''}
								</p>
								<p
									style={{
										fontSize: 10,
									}}>
									{onwardFlight.Supplier === 'TBO'
										? onwardFlight.Segments[0].length == 2
											? onwardFlight.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: onwardFlight.Segments[0].length == 3
											? onwardFlight.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: onwardFlight.Segments[0].length == 1
											? onwardFlight.Segments[0].map((it) => {
													return it.Destination.Airport.AirportCode;
											  })
											: ''
										: ''}
								</p>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box display={'flex'} alignItems={'center'}>
							<img
								src={`https://nitish.musafirbazar.com/static/media/${
									returnFlight.Supplier === 'TBO'
										? returnFlight.AirlineCode
										: ''
								}.gif`}
								style={{ borderRadius: 5, height: 25 }}
							/>
							<Box ml={1}>
								<p
									title={
										returnFlight.Supplier === 'TBO'
											? returnFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.AirlineName;
											  })
											: ''
									}
									style={{
										fontWeight: 'bold',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
										width: '50px',
										height: '1.2em',
										whiteSpace: 'nowrap',
										fontSize: 10,
									}}>
									{returnFlight.Supplier === 'TBO'
										? returnFlight.Segments[0].slice(0, 1).map((i) => {
												return i.Airline.AirlineName;
										  })
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{`${
										returnFlight.Supplier === 'TBO'
											? returnFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.AirlineCode;
											  })
											: ''
									}-${
										returnFlight.Supplier === 'TBO'
											? returnFlight.Segments[0].slice(0, 1).map((i) => {
													return i.Airline.FlightNumber;
											  })
											: ''
									}`}
								</p>
							</Box>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{returnFlight.Supplier
										? returnFlight.Segments[0].slice(0, 1).map((i) => {
												return i.Origin.DepTime.substr(11, 5);
										  })
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{returnFlight.Supplier === 'TBO'
										? returnFlight.FareRules[0].Origin
										: ''}
								</p>
							</Box>
							<Box textAlign={'center'} alignItems={'center'}>
								<p style={{ fontSize: 10 }}>
									{timeConvert(
										returnFlight.Supplier === 'TBO'
											? returnFlight.Segments[0].length == 2
												? timedifference(
														returnFlight.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														returnFlight.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: returnFlight.Segments[0].length == 1
												? returnFlight.Segments[0].map((i) =>
														timedifference(
															returnFlight.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Origin.DepTime;
																}),
															returnFlight.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Destination.ArrTime;
																})
														)
												  )
												: returnFlight.Segments[0].length == 3
												? timedifference(
														returnFlight.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														returnFlight.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: ''
											: ''
									)}
								</p>
								<img src={Line} style={{ width: '50%', marginLeft: 40 }} />
								<p style={{ fontSize: 10 }}>
									{returnFlight.Supplier === 'TBO'
										? returnFlight.Segments[0].length === 1
											? 'Non-Stop'
											: returnFlight.Segments[0].length === 2
											? '1 Stop'
											: returnFlight.Segments[0].length === 3
											? '2 Stop'
											: ''
										: ''}
								</p>
							</Box>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{returnFlight.Supplier === 'TBO'
										? returnFlight.Segments[0].length == 3
											? returnFlight.Segments[0]
													.filter((i, index) => index === 2)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: returnFlight.Segments[0].length == 2
											? returnFlight.Segments[0]
													.filter((i, index) => index === 1)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: returnFlight.Segments[0].length == 1
											? returnFlight.Segments[0]
													.filter((i, index) => index === 0)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: ''
										: ''}
								</p>
								<p
									style={{
										fontSize: 10,
									}}>
									{returnFlight.Supplier === 'TBO'
										? returnFlight.Segments[0].length == 2
											? returnFlight.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: returnFlight.Segments[0].length == 3
											? returnFlight.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: returnFlight.Segments[0].length == 1
											? returnFlight.Segments[0].map((it) => {
													return it.Destination.Airport.AirportCode;
											  })
											: ''
										: ''}
								</p>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'space-around'}>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 18,
									}}>
									₹ 
									{onwardFlight.Supplier === 'TBO'
										? Number(
												Math.round(
													Number(
														Number(Math.round(onwardFlight.Fare.Total_Amount)) -
															Number(Math.round(onwardFlight.Fare.MFB_Discount))
													) +
														Number(
															Number(
																Math.round(returnFlight.Fare.Total_Amount)
															) -
																Number(
																	Math.round(returnFlight.Fare.MFB_Discount)
																)
														)
												)
										  )
										: ''}
								</p>
							</Box>
							<Button
								onClick={() =>
									navigate('/flightreview', {
										state: {
											onwardFlight: onwardFlight,
											returnFlight: returnFlight,
											formData: location.state,
											traceId: tbo?.result?.Response.TraceId,
											orderId: orderId.result,
										},
									})
								}
								style={{
									background: 'rgb(12 163 153)',
									color: 'white',
									fontSize: 12,
								}}>
								Book Now
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default FlightDomRountrip;
