import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './SideBar';
import { Box } from '@mui/material';
const MainLayout = ({ children }) => {
	const { agentInfo, walletagent } = useSelector((state) => state.agent);
	return (
		<>
			<div style={{ display: 'flex' }}>
				<SideBar />
				<div
					style={{
						flexGrow: 1,
						backgroundColor: '#FFF',
						minHeight: '100vh',
						width: '83vw',
					}}>
					<Box
						style={{
							boxShadow:
								'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
							height: 64,
						}}>
						<Box display={'flex'} justifyContent={'space-between'} mr={2}>
							<Box></Box>
							<Box mt={2}>
								<p style={{ fontSize: 12 }}>
									Hi, {agentInfo?.result?.result?.name}
								</p>
								<span style={{ fontSize: 12 }}>
									Available Balance ₹ {walletagent?.result?.balanceAmount}
								</span>
							</Box>
						</Box>
					</Box>
					{children}
				</div>
			</div>
		</>
	);
};

export default MainLayout;
