import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

export default function TranTable() {
	const { transHistory } = useSelector((state) => state.agent);
	const columns = [
		{ field: 'cart_id', headerName: 'OrderId', width: 150 },
		{ field: 'type', headerName: 'Transaction Type', width: 150 },
		{ field: 'commission', headerName: 'Commission', width: 150 },
		{ field: 'markup', headerName: 'Markup', width: 150 },
		{ field: 'amount', headerName: 'Amount', width: 150 },
		{ field: 'avail_bal', headerName: 'Available Balance', width: 200 },
		{ field: 'payment_type', headerName: 'Payment Type', width: 150 },
		{ field: 'date', headerName: 'Date', width: 150 },
		{
			field: 'description',
			headerName: 'Description',
			width: 300,
			editable: true,
		},
	];
	return (
		<Box sx={{ marginTop: 3 }}>
			{transHistory && transHistory?.result.result?.length > 0 ? (
				<DataGrid
					rows={transHistory?.result?.result}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					// checkboxSelection
					disableRowSelectionOnClick
				/>
			) : (
				<p>No Record Found</p>
			)}
		</Box>
	);
}
