import React from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';

const BusTicket = () => {
	const location = useLocation();
	function timeConvertbus(a) {
		let arhour = Math.floor(a / 60);
		if (arhour > 24) {
			arhour = arhour - 24;
		}
		let armin = a % 60;
		let format = `${arhour}:${armin === 0 ? '00' : armin}:00`;
		function formatTime(timeString) {
			const [hourString, minute] = timeString.split(':');
			const hour = +hourString % 24;
			return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
		}
		return formatTime(format);
	}
	console.log('location', location);
	const style = {
		table: {
			background: '#fff',
			margin: '0px auto',
			width: '850px !important',
			padding: '30px 20px',
			webkitPrintColorAdjust: 'exact',
		},
		table2: {
			boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
			background: '#fff',
			borderSpacing: 0,
			padding: 0,
			margin: 0,
		},
		table3: {
			width: '100%',
			padding: 0,
			margin: 0,
		},
		row: {
			width: '60%',
			padding: '10px',
		},
		row2: {
			width: '50%',
			border: '1px solid #333',
			borderLeft: 0,
			padding: '8px',
		},
		row3: {
			width: '50%',
			borderTop: '1px solid #333',
			borderBottom: '1px solid #333',
			padding: '5px',
		},
	};
	return (
		<>
			<Navbar />
			<Container style={{ padding: 30 }}>
				<table className={style.table}>
					<tbody>
						<tr>
							<td>
								<table className={style.table2}>
									<tbody>
										<tr>
											<td>
												<table className={style.table3}>
													<tbody>
														<tr>
															<td className={style.row}>
																<img
																	src={
																		'https://www.marinersforex.com/static/media/logo.0c442bcbbe9fc837b472.png'
																	}
																	style={{
																		maxHeight: 50,
																		maxWidth: 150,
																	}}></img>
															</td>
															<td style={{ width: '40%', padding: 10 }}>
																<p
																	style={{
																		fontWeight: 600,
																		marginTop: 5,
																	}}>
																	MarinersForex
																</p>
																<p
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																		color: '#777',
																	}}>
																	Email: info@marinersforex.com
																</p>
																<p
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																		color: '#777',
																	}}>
																	Phone: +91 7007102425
																</p>
																<p
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																		color: '#777',
																	}}>
																	Address: Block - B 31, First Floor, Chandigarh
																	Citi Center, VIP Rd, Zirakpur, Punjab 140603
																</p>
															</td>
														</tr>
													</tbody>
												</table>
												<table>
													<tbody>
														<tr>
															<td style={{ padding: 10 }}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Ticket Reservation
																</span>
																<span
																	style={{ color: 'darkblue', fontSize: 13 }}>
																	{' '}
																	Please take a print of this ticket. A copy of
																	the ticket has to be produced at the time of
																	boarding. Please be present atleast 15 mins
																	prior to time of departure at the boarding
																	poin
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	// paddingLeft: 7,
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Pax Details
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Ticket Number
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Seat Number
																</span>
															</td>
														</tr>
														{location?.state?.bookingDetail?.result &&
														location?.state?.bookingDetail?.result
															.inventoryItems.length > 0 ? (
															location?.state?.bookingDetail?.result.inventoryItems.map(
																(item, index) => {
																	return (
																		<tr>
																			<td
																				style={{
																					padding: 8,
																					border: '1px solid lightblue',
																				}}>
																				<span>{`${item.passenger.name}`}</span>
																			</td>
																			<td
																				style={{
																					padding: 8,
																					border: '1px solid lightblue',
																				}}>
																				<span>
																					{
																						location?.state?.bookingDetail
																							?.result.pnr
																					}
																				</span>
																			</td>
																			<td
																				style={{
																					padding: 8,
																					border: '1px solid lightblue',
																				}}>
																				<span>{item.seatName}</span>
																			</td>
																		</tr>
																	);
																}
															)
														) : (
															<tr>
																<td
																	style={{
																		padding: 8,
																		border: '1px solid lightblue',
																	}}>
																	<span>{`${location?.state?.bookingDetail?.result.inventoryItems.passenger.name}`}</span>
																</td>
																<td
																	style={{
																		padding: 8,
																		border: '1px solid lightblue',
																	}}>
																	<span>
																		{location?.state?.bookingDetail?.result.pnr}
																	</span>
																</td>
																<td
																	style={{
																		padding: 8,
																		border: '1px solid lightblue',
																	}}>
																	<span>
																		{
																			location?.state?.bookingDetail?.result
																				.inventoryItems.seatName
																		}
																	</span>
																</td>
															</tr>
														)}
													</tbody>
												</table>

												<table style={{ marginTop: 10, width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	PNR:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{location?.state?.bookingDetail?.result.pnr}
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Price:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{Array.isArray(
																		location.state.bookingDetail?.result
																			?.inventoryItems
																	)
																		? location.state.bookingDetail &&
																		  location.state.bookingDetail?.result
																				?.inventoryItems?.length > 0 &&
																		  location.state.bookingDetail?.result?.inventoryItems
																				?.map((item) => parseInt(item.fare))
																				.reduce((prev, next) => prev + next)
																		: location.state.bookingDetail?.result
																				?.inventoryItems.fare}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	From:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.sourceCity
																	}
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	To:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.destinationCity
																	}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Travels:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.travels
																	}
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Bus Type:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.busType
																	}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Journey Date:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result.doj.split(
																			'T'
																		)[0]
																	}
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Dep Time:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{timeConvertbus(
																		location?.state?.bookingDetail?.result
																			.pickupTime
																	)}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ marginTop: 10, width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Boarding Address:
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	// border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Location:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.pickupLocation
																	}
																</span>
															</td>
														</tr>
														<tr>
															<td
																style={{
																	padding: 8,
																	// border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Landmark:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.pickupLocationLandmark
																	}
																</span>
															</td>
														</tr>
														<tr>
															<td
																style={{
																	padding: 8,
																	// border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Address:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.pickUpLocationAddress
																	}
																</span>
															</td>
														</tr>
														<tr>
															{/* <td
																style={{
																	padding: 8,
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Boarding Time:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{timeConvertbus(
																		location?.state?.bookingDetail?.result
																			.firstBoardingPointTime
																	)}
																</span>
															</td> */}
														</tr>
														<tr>
															<td
																style={{
																	padding: 8,
																	// border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Contact Number:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{
																		location?.state?.bookingDetail?.result
																			.pickUpContactNo
																	}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ marginTop: 10, width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Cancellation Detail
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Cancellation Time
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Cancellation Charges
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ marginTop: 10, width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Contact Details:
																</span>
															</td>
														</tr>
													</tbody>
												</table>
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Phone:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{location?.state?.detail?.state.data.phone}
																</span>
															</td>
															<td
																style={{
																	padding: 8,
																	border: '1px solid lightblue',
																}}>
																<span
																	style={{
																		color: 'darkblue',
																		fontWeight: 'bold',
																	}}>
																	Email:
																</span>
																<span style={{ marginLeft: 5 }}>
																	{location?.state?.detail?.state.data.email}
																</span>
															</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<table className={style.table}>
					<tbody>
						<tr>
							<td>
								{/* <LoadingButton
								id='myPrntbtn'
								onClick={() => window.print()}
								loadingPosition='start'
								startIcon={<PrintIcon />}
								variant='contained'>
								Print
							</LoadingButton> */}
							</td>
							{/* <td>
							<LoadingButton
								id='download'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download
							</LoadingButton>
						</td> */}
							{/* <td>
							<LoadingButton
								id='downloadwprice'
								loadingPosition='start'
								startIcon={<DownloadForOfflineIcon />}
								variant='contained'>
								Download without price
							</LoadingButton>
						</td> */}
							<td>
								{/* <LoadingButton
								id='mail'
								// color='secondary'
								onClick={handleClick}
								// loading={loading}
								loadingPosition='start'
								startIcon={<EmailIcon />}
								variant='contained'>
								Mail
							</LoadingButton> */}
							</td>
						</tr>
					</tbody>
				</table>
			</Container>
			<Footer />
		</>
	);
};

export default BusTicket;
