import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import {
	TBOBookGDSDispatch,
	TBOBookGDSONWARDDispatch,
	TBOBookGDSRETURNDispatch,
	TBOBookLCCDispatch,
	TBOBookLCCONWARDDispatch,
	TBOBookLCCRETURNDispatch,
} from '../reducers/TBOReducer';
import Footer from '../components/resuableComponents/Footer';
function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const PaymentPage = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const [load, setLoading] = useState(false);
	const navigate = useNavigate();
	const {
		formData,
		bookingDataOneway,
		addBookingData,
		depart,
		arrival,
		bookingDataONWARD,
		bookingDataRETURN,
	} = useSelector((state) => state.ui);
	const { loading, loadingReturn } = useSelector((state) => state.tbo);
	useEffect(() => {
		if (query.get('status') === 'success') {
			if (formData.JourneyType === 1) {
				if (addBookingData.airlineType === 'LCC') {
					dispatch(TBOBookLCCDispatch(bookingDataOneway, setLoading, navigate));
				} else {
					dispatch(TBOBookGDSDispatch(bookingDataOneway, setLoading, navigate));
				}
			} else {
				if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
					if (
						addBookingData.selectedFlight[0]?.ResultIndex ===
						bookingDataONWARD?.ResultIndex
					) {
						if (addBookingData.selectedFlight[0]?.IsLCC) {
							dispatch(
								TBOBookLCCONWARDDispatch(
									bookingDataONWARD,
									setLoading,
									navigate
								)
							);
						} else {
							dispatch(
								TBOBookGDSONWARDDispatch(
									bookingDataONWARD,
									setLoading,
									navigate
								)
							);
						}
					}
					if (
						addBookingData.selectedFlight[1].ResultIndex ===
						bookingDataRETURN?.ResultIndex
					) {
						if (addBookingData.selectedFlight[1]?.IsLCC) {
							dispatch(
								TBOBookLCCRETURNDispatch(
									bookingDataRETURN,
									setLoading,
									navigate
								)
							);
						} else {
							dispatch(
								TBOBookGDSRETURNDispatch(
									bookingDataRETURN,
									setLoading,
									navigate
								)
							);
						}
					}
				} else {
					if (addBookingData.airlineType === 'LCC') {
						dispatch(
							TBOBookLCCDispatch(bookingDataOneway, setLoading, navigate)
						);
					} else {
						dispatch(
							TBOBookGDSDispatch(bookingDataOneway, setLoading, navigate)
						);
					}
				}
			}
		}
	}, []);
	return (
		<>
			<Navbar />
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: '#F8F8F8AD',
				}}>
				<div
					style={{
						left: '30%',
						top: '30%',
						zIndex: 1000,
						position: 'absolute',
					}}>
					{loading || loadingReturn ? (
						<>
							<Box style={{ textAlign: 'center' }}>
								<div style={{ fontSize: 35, textAlign: 'center' }}>
									Don't Refresh This Page
								</div>
								<div style={{ fontSize: 35, textAlign: 'center' }}>
									Booking is UnderProcess Please Wait.....
								</div>
								<div style={{ textAlign: 'center' }}>
									<img
										style={{ height: 150, marginLeft: 180, marginTop: 20 }}
										src={
											'https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif'
										}
									/>
								</div>
							</Box>
						</>
					) : (
						<>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								!!Ticket might have been issued.!!
							</div>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								Please Contact Your Supplier
							</div>
						</>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default PaymentPage;
