import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import FlightResult from '../pages/FlightResult';
import ReviewFlight from '../pages/ReviewFlight';
import ContactPage from '../pages/ContactPage';
import FlightHomePage from '../pages/FlightHomePage';
import HotelHomePage from '../pages/HotelHomePage';
import ConfirmFlightPage from '../pages/ConfirmFlightPage';
import HotelResultPage from '../pages/HotelResultPage';
import HotelReview from '../pages/HotelReview';
import ConfirmHotelPage from '../pages/ConfirmHotelPage';
import FlightBookPage from '../pages/FlightBookPage';
import FlightResultRoundtrip from '../pages/FlightResultRoundtrip';
import FlightResultInternationalRoundtrip from '../pages/FlightResultInternationalRoudtrip';
import FlightTicketLCC from '../pages/FlightTicketLCC';
import MainLayout from './resuableComponents/MainLayout';
import TransactionHistory from './resuableComponents/TransactionHistory';
import BookingHistory from './resuableComponents/BookingHistory';
import DashboardPage from '../pages/DashboardPage';
import Profile from './resuableComponents/Profile';
import ChangePassword from './resuableComponents/ChangePassword';
import PaymentPage from '../pages/PaymentPage';
import FailedPage from '../pages/FailedPage';
import ManageMarkup from './resuableComponents/ManageMarkup';
import BookingDetail from './resuableComponents/BookingDetail';
import CompanyInfo from '../pages/CompanyInfo';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CompanyPolicy from '../pages/CompanyPolicy';
import UserAgreement from '../pages/UserAgreement';
import TermsCondition from '../pages/TermsCondition';
import AboutUsPage from '../pages/AboutUsPage';
import CareersPage from '../pages/CareersPage';
import DisclaimerPage from '../pages/DisclaimerPage';
import BaggageAllowance from '../pages/BaggageAllowance';
import CancelationRefundPage from '../pages/CancelationRefundPage';
import CustomerCarePage from '../pages/CustomerCarePage';
import FeedBackForm from '../pages/FeedBackForm';
import FAQ from '../pages/FAQ';
import InternationalAirportPage from '../pages/InternationalAirportPage';
import DomesticAirlinePage from '../pages/DomesticAirlinePage';
import InternationalAirlinePage from '../pages/InternationalAirlinePage';
import DomesticFlight from '../pages/DomesticFlight';
import InternationalFlight from '../pages/InternationalFlight';
import BusHomePage from '../pages/BusHomePage';
import BusResult from '../pages/BusResult';
import BusReview from '../pages/BusReview';
import BusConfirm from '../pages/BusConfirm';
import BusTicket from '../pages/BusTicket';
import PaymentPageBus from '../pages/PaymentPageBus';
import BookingHistoryBus from './resuableComponents/BookingHistoryBus';

const MainRouter = () => {
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<FlightHomePage />} />
				<Route path='/flight' exact element={<FlightHomePage />} />
				<Route path='/hotel' exact element={<HotelHomePage />} />
				<Route path='/busbooking' exact element={<BusHomePage />} />
				<Route path='/hotelresult' exact element={<HotelResultPage />} />
				<Route path='/flightresult' exact element={<FlightResult />} />
				<Route path='/busresult' exact element={<BusResult />} />
				<Route path='/busreiew' exact element={<BusReview />} />
				<Route path='/busconfirm' exact element={<BusConfirm />} />
				<Route
					path='/flightroundtripresult'
					exact
					element={<FlightResultRoundtrip />}
				/>
				<Route
					path='/flightroundInternationaltripresult'
					exact
					element={<FlightResultInternationalRoundtrip />}
				/>
				<Route path='/flightreview' exact element={<ReviewFlight />} />
				<Route path='/hotelreview' exact element={<HotelReview />} />
				<Route path='/hotelconfirm' exact element={<ConfirmHotelPage />} />
				<Route path='/flightconfirm' exact element={<ConfirmFlightPage />} />
				<Route path='/flightbook' exact element={<FlightBookPage />} />
				<Route path='/flightticket' exact element={<FlightTicketLCC />} />
				<Route path='/busticket' exact element={<BusTicket />} />
				<Route path='/paymentpage' exact element={<PaymentPage />} />
				<Route path='/paymentpageBus' exact element={<PaymentPageBus />} />
				<Route path='/companyinfo' exact element={<CompanyInfo />} />
				<Route path='/privacypolicy' exact element={<PrivacyPolicy />} />
				<Route path='/companypolicy' exact element={<CompanyPolicy />} />
				<Route path='/useragreement' exact element={<UserAgreement />} />
				<Route path='/termsandcondition' exact element={<TermsCondition />} />
				<Route path='/aboutus' exact element={<AboutUsPage />} />
				<Route path='/careers' exact element={<CareersPage />} />
				<Route path='/disclaimer' exact element={<DisclaimerPage />} />
				<Route path='/contactus' exact element={<ContactPage />} />
				<Route path='/baggageallowance' exact element={<BaggageAllowance />} />
				<Route path='/customercare' exact element={<CustomerCarePage />} />
				<Route
					path='/domesticairline'
					exact
					element={<DomesticAirlinePage />}
				/>
				<Route
					path='/internationalairline'
					exact
					element={<InternationalAirlinePage />}
				/>{' '}
				<Route path='/domesticflight' exact element={<DomesticFlight />} />
				<Route
					path='/internationalflight'
					exact
					element={<InternationalFlight />}
				/>
				<Route path='/feedbackform' exact element={<FeedBackForm />} />
				<Route
					path='/internationalAiprort'
					exact
					element={<InternationalAirportPage />}
				/>
				<Route path='/faq' exact element={<FAQ />} />
				<Route
					path='/cancellationandrefund'
					exact
					element={<CancelationRefundPage />}
				/>
				<Route path='/failed' exact element={<FailedPage />} />
				<Route
					path='/dashboard'
					element={
						<>
							<MainLayout>
								<DashboardPage />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/transhistory'
					element={
						<>
							<MainLayout>
								<TransactionHistory />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/profile'
					element={
						<>
							<MainLayout>
								<Profile />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/changepassword'
					element={
						<>
							<MainLayout>
								<ChangePassword />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/managemarkup'
					element={
						<>
							<MainLayout>
								<ManageMarkup />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/bookinghistory'
					element={
						<>
							<MainLayout>
								<BookingHistory />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/bookinghistorybus'
					element={
						<>
							<MainLayout>
								<BookingHistoryBus />
							</MainLayout>
						</>
					}
				/>
				<Route
					path='/bookingdetails'
					element={
						<>
							<MainLayout>
								<BookingDetail />
							</MainLayout>
						</>
					}
				/>
				<Route path='*' exact element={<h1>Page Not found</h1>} />
			</Routes>
		</div>
	);
};

export default MainRouter;
