import { Grid, Box, Card } from '@mui/material';
import React, { useEffect } from 'react';
import FlightIcon from '@mui/icons-material/Flight';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TranTable from '../components/resuableComponents/TransTable';
import {
	agentbookinghistoryDispatch,
	agenttransactionhistoryDispatch,
	agentBusbookinghistoryDispatch,
} from '../reducers/AgentReducer';
import { useDispatch, useSelector } from 'react-redux';
import DoughnutChart from '../components/resuableComponents/Doughnut';
import LoaderModal from '../components/resuableComponents/LoaderModal';

const DashboardPage = () => {
	const { agentInfo, bookingHistory, transHistory, walletagent, loading } =
		useSelector((state) => state.agent);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(agentbookinghistoryDispatch(agentInfo?.result?.result?.id));
		dispatch(agentBusbookinghistoryDispatch(agentInfo?.result?.result?.id));
		dispatch(agenttransactionhistoryDispatch(agentInfo?.result?.result?.id));
	}, []);
	return (
		<>
			<Grid container spacing={2} style={{ padding: 20 }}>
				<Grid item xs={8}>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<Box
								mt={3}
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
									padding: 20,
									borderRadius: 5,
									// height: 60,
								}}>
								<Box display={'flex'} alignItems={'center'}>
									<FlightIcon />
									<Box>
										<span style={{ marginLeft: 12 }}>Flight Booked</span>
										<p style={{ marginLeft: 12, fontWeight: 'bold' }}>
											{bookingHistory?.result?.flights?.length
												? bookingHistory?.result?.flights?.length
												: 0}
										</p>
									</Box>
								</Box>
								{/* <br></br> */}
							</Box>
						</Grid>
						<Grid item xs={4}>
							<Box
								mt={3}
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
									padding: 20,
									borderRadius: 5,
									// height: 60,
								}}>
								<Box display={'flex'} alignItems={'center'}>
									<ReceiptLongIcon />
									<Box>
										<span style={{ marginLeft: 12 }}>Total Transaction</span>
										<p style={{ marginLeft: 12, fontWeight: 'bold' }}>
											{transHistory?.result?.result?.length
												? transHistory?.result?.result?.length
												: 0}
										</p>
									</Box>
								</Box>
								{/* <br></br> */}
							</Box>
						</Grid>
						<Grid item xs={4}>
							<Box
								mt={3}
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
									padding: 20,
									borderRadius: 5,
									// height: 60,
								}}>
								<Box display={'flex'} alignItems={'center'}>
									<CurrencyRupeeIcon />
									<Box>
										<span style={{ marginLeft: 12 }}>Available Balance</span>
										<p style={{ marginLeft: 12, fontWeight: 'bold' }}>
											{walletagent?.result?.balanceAmount}
										</p>
									</Box>
								</Box>
								{/* <br></br> */}
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box
								mt={3}
								// display={'flex'}
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
									padding: 20,
									borderRadius: 5,
									width: '100%',
									height: 450,
								}}>
								<h1>Lastest Flight Booking</h1>
								<br />

								{bookingHistory?.result?.flights?.length > 0 ? (
									bookingHistory?.result?.flights?.map((i, index) => {
										return index < 6 ? (
											<Box
												mt={2}
												display={'flex'}
												justifyContent={'space-between'}
												style={{
													padding: 15,
													marginTop: 5,
													borderRadius: 5,
													boxShadow:
														'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
												}}>
												<p style={{ fontSize: 14 }}>{index + 1}</p>
												<p style={{ fontSize: 14 }}>{i.origin}</p>
												<p style={{ fontSize: 14 }}>{i.destination}</p>
												<p style={{ fontSize: 14 }}>{i.bookingRefrence}</p>
												<p style={{ fontSize: 14 }}>{i.dob}</p>
												<p style={{ fontSize: 14 }}>{i.journeyType}</p>
											</Box>
										) : (
											<p>No Record Found</p>
										);
									})
								) : (
									<p>No Record Found</p>
								)}
								{/* <TranTable /> */}
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<Card sx={{ px: 3, py: 2, mb: 3 }}>
						<h2>Flight Booking Analysis</h2>

						<DoughnutChart
							height='300px'
							color={['rgb(8 128 130)', '#232a45', 'pink']}
						/>
					</Card>
					{/* <Card sx={{ px: 3, py: 2, mb: 3 }}>
						<h2>Flight Booking Analysis</h2>

						<DoughnutChart
							height='300px'
							color={['rgb(8 128 130)', '#232a45', 'pink']}
						/>
					</Card> */}
					{/* <FlightIcon />
						<span style={{ marginLeft: 12 }}>Available Balance</span>
						<br></br>
						<p style={{ marginLeft: 12, fontWeight: 'bold' }}>53</p> */}
				</Grid>
			</Grid>
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default DashboardPage;
