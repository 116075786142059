import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import Footer from '../components/resuableComponents/Footer';
import BusRow from '../components/resuableComponents/BusRow';
import { Container, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addBusSearchDispatch } from '../reducers/BusReducer';
import ModifyBusSearch from '../components/resuableComponents/ModifyBusSearch';

const BusResult = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { busResult, loading } = useSelector((state) => state.bus);
	console.log('location', location);
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location?.state) {
			dispatch(addBusSearchDispatch(location.state));
		}
	}, [location?.state]);
	let filterData = [];
	if (busResult?.result?.availableTrips?.length > 0) {
		filterData =
			busResult &&
			busResult.result &&
			busResult.result.availableTrips &&
			busResult.result.availableTrips.length > 0 &&
			busResult.result.availableTrips.slice().sort((a, b) => {
				let first = Array.isArray(a?.fares) ? a?.fares[0] : a.fares;
				let second = Array.isArray(b?.fares) ? b?.fares[0] : b.fares;
				return first - second;
			});
		console.log('fILTER', filterData);
	}
	return (
		<>
			<Navbar />
			<MNavbar />
			<Box style={{ background: 'lightgrey', padding: '6px 0px' }}>
				<Container style={{}}>
					<Box
						mt={2}
						style={{
							boxShadow:
								'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
							padding: 15,
							background: 'white',
							borderRadius: 5,
						}}>
						<h2>Modify Search</h2>
						<ModifyBusSearch />
					</Box>
				</Container>
			</Box>
			<Container style={{ marginTop: 20 }}>
				<Box
					mt={2}
					style={{
						boxShadow:
							'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
					}}>
					<Box
						style={{ padding: 25 }}
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Box>{filterData?.length} services</Box>
						<Box>Sort By:</Box>
					</Box>
				</Box>
				{loading ? (
					<>
						<p>loading....</p>
					</>
				) : filterData?.length > 0 ? (
					filterData?.map((i) => {
						return <BusRow item={i} />;
					})
				) : (
					''
				)}
			</Container>
			<Footer />
		</>
	);
};

export default BusResult;
