import React from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, Box, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	agentEditMarkupDispatch,
	agentedituserDispatch,
} from '../reducers/AgentReducer';

const ManageMarkupForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { agentInfo, agentMarkup } = useSelector((state) => state.agent);
	const handleFormSubmit = (data) => {
		const markup = [
			{
				inventory:
					agentMarkup && agentMarkup.result && agentMarkup.result[6].inventory,
				agent_id: agentInfo && agentInfo.result.result.id,
				type: data.Domestictype,
				value: data.Domestic,
			},
			{
				inventory:
					agentMarkup && agentMarkup.result && agentMarkup.result[4].inventory,
				agent_id: agentInfo && agentInfo.result.result.id,
				type: data.HotelType,
				value: data.Hotel,
			},
			{
				inventory:
					agentMarkup && agentMarkup.result && agentMarkup.result[3].inventory,
				agent_id: agentInfo && agentInfo.result.result.id,
				type: data.InHotelType,
				value: data.InHotel,
			},
			{
				inventory:
					agentMarkup && agentMarkup.result && agentMarkup.result[2].inventory,
				agent_id: agentInfo && agentInfo.result.result.id,
				type: data.BusType,
				value: data.Bus,
			},
			{
				inventory:
					agentMarkup && agentMarkup.result && agentMarkup.result[5].inventory,
				agent_id: agentInfo && agentInfo.result.result.id,
				type: data.InFlightType,
				value: data.InFlight,
			},
		];
		let formdata = {
			markup: markup,
		};
		dispatch(
			agentEditMarkupDispatch(formdata, agentInfo && agentInfo.result.result.id)
		);
	};
	return (
		<Formik
			initialValues={{
				Domestic:
					agentMarkup && agentMarkup.result && agentMarkup.result[6].value,
				Domestictype:
					agentMarkup && agentMarkup.result && agentMarkup.result[6].type,
				Hotel: agentMarkup && agentMarkup.result && agentMarkup.result[4].value,
				HotelType:
					agentMarkup && agentMarkup.result && agentMarkup.result[4].type,
				InHotel:
					agentMarkup && agentMarkup.result && agentMarkup.result[3].value,
				InHotelType:
					agentMarkup && agentMarkup.result && agentMarkup.result[3].type,
				Bus: agentMarkup && agentMarkup.result && agentMarkup.result[2].value,
				BusType:
					agentMarkup && agentMarkup.result && agentMarkup.result[2].type,
				InFlight:
					agentMarkup && agentMarkup.result && agentMarkup.result[5].value,
				InFlightType:
					agentMarkup && agentMarkup.result && agentMarkup.result[5].type,
			}}
			onSubmit={handleFormSubmit}>
			{(formik) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box style={{ padding: 20 }}>
							<Box
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px',
									padding: 20,
									background: 'white',
								}}>
								<Box display={'flex'} justifyContent={'space-between'}>
									<h2>Manage Markup</h2>
									<h2>
										{/* {`Wallet Amount: ₹ ${loginInfo?.result?.walletAmount}`} */}
									</h2>
								</Box>
								<Divider style={{ marginTop: 20, marginBottom: 20 }} />
								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Domestic Flight Markup</span>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='Domestic'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.name
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.Domestic ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.Domestic}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={2}>
										<Field
											as='select'
											name='Domestictype'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.Domestictype
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
												marginLeft: 5,
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											<option value='%'>%</option>
											<option value='Rs'>Rs</option>
										</Field>
									</Grid>
								</Grid>
								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>
											International Flight Markup
										</span>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='InFlight'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.InFlight
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.InFlight ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.InFlight}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={2}>
										<Field
											as='select'
											name='InFlightType'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.InFlightType
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
												marginLeft: 5,
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											<option value='%'>%</option>
											<option value='Rs'>Rs</option>
										</Field>
									</Grid>
								</Grid>

								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Hotel Markup</span>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='Hotel'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.Hotel
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.Hotel ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.Hotel}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={2}>
										<Field
											as='select'
											name='HotelType'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.HotelType
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
												marginLeft: 5,
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											<option value='%'>%</option>
											<option value='Rs'>Rs</option>
										</Field>
									</Grid>
								</Grid>
								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>
											International Hotel Markup
										</span>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='InHotel'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.InHotel
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.InHotel ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.InHotel}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={2}>
										<Field
											as='select'
											name='InHotelType'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.InHotelType
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
												marginLeft: 5,
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											<option value='%'>%</option>
											<option value='Rs'>Rs</option>
										</Field>
									</Grid>
								</Grid>
								<Grid container alignItems={'center'}>
									<Grid item xs={4}>
										<span style={{ fontSize: 16 }}>Bus Markup</span>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='Bus'
											type='text'
											placeHolder=''
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.Bus
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.Bus ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.Bus}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={2}>
										<Field
											as='select'
											name='BusType'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.BusType
													? '2px solid red'
													: '1px solid lightgrey',
												marginTop: 10,
												marginLeft: 5,
											}}
											inputProps={{
												style: { padding: 12 },
											}}>
											<option value='%'>%</option>
											<option value='Rs'>Rs</option>
										</Field>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={3}></Grid>
									<Grid item xs={3}></Grid>
									<Grid item xs={12} lg={3}>
										<Button
											type='submit'
											style={{
												background: 'rgb(25, 118, 210)',
												color: 'white',
												marginTop: 30,
												// padding: 16,
												borderRadius: 5,
												width: '100%',
											}}>
											Save Changes
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ManageMarkupForm;
