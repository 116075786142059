import React from 'react';
import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ManageMarkupForm from '../../forms/ManageMarkupForm';
import LoaderModal from './LoaderModal';

const ManageMarkup = () => {
	const { loading } = useSelector((state) => state.agent);
	useEffect(() => {}, []);
	return (
		<div>
			<Box mt={3} style={{ padding: 20 }}>
				<h2>Manage Markup</h2>
				<Divider style={{ marginTop: 20 }} />
				<ManageMarkupForm />
			</Box>
			{loading ? <LoaderModal /> : ''}
		</div>
	);
};

export default ManageMarkup;
