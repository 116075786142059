import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Box, Grid, Button } from '@mui/material';
import { DatePicker, Divider } from 'antd';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
const title = ['', 'Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];
const dateFormat = 'YYYY-MM-DD';

const HotelSummaryForm = () => {
	const location = useLocation();
	const naviagte = useNavigate();
	const [value, setValue] = useState(0);
	const [value2, setValue2] = useState(0);
	const [value3, setValue3] = useState(0);
	const { depart, arrival, room, adultH, childH } = useSelector(
		(state) => state.ui
	);
	const handleFormSubmit = (data) => {
		console.log('Data', data);
		// naviagte('/flightconfirm', {
		// 	state: {
		// 		item: location.state.item,
		// 		passengerData: data,
		// 		formData: location.state.formData,
		// 		traceId: location.state.traceId,
		// 	},
		// });
	};
	console.log('location', location);
	function disabledDate(current) {
		return current && current < moment().endOf('day');
	}
	let arr = [];
	let arr2 = [];
	let arr3 = [];
	for (let i = 1; i <= room?.length; i++) {
		arr.push({});
	}
	for (let i = 1; i <= room?.length; i++) {
		arr2.push({});
	}

	return (
		<>
			<Formik
				initialValues={{
					email: '',
					phone: '',
					adult: arr,
					child: arr2,
					// infant: arr3,
				}}
				validationSchema={
					depart.country_code === 'IN' && arrival.country_code === 'IN'
						? Yup.object({
								email: Yup.string()
									.email('Invalid email')
									.required('Email Required'),
								phone: Yup.string().required('Phone required'),
								// adult: Yup.array().of(
								// 	Yup.object().shape({
								// 		firstname: Yup.string().required('firstname required'),
								// 		lastname: Yup.string().required('lastname required'),
								// 		title: Yup.string().required('title required'),
								// 		gender: Yup.string().required('gender required'),
								// 	})
								// ),
								// child: Yup.array().of(
								// 	Yup.object().shape({
								// 		firstname: Yup.string().required('firstname required'),
								// 		lastname: Yup.string().required('lastname required'),
								// 		title: Yup.string().required('title required'),
								// 		gender: Yup.string().required('gender required'),
								// 		dob: Yup.string().required('Date of Birth required'),
								// 	})
								// ),
								// infant: Yup.array().of(
								// 	Yup.object().shape({
								// 		firstname: Yup.string().required('firstname required'),
								// 		lastname: Yup.string().required('lastname required'),
								// 		title: Yup.string().required('title required'),
								// 		gender: Yup.string().required('gender required'),
								// 		dob: Yup.string().required('Date of Birth required'),
								// 	})
								// ),
						  })
						: ''
				}
				onSubmit={handleFormSubmit}>
				{(formik, values) => {
					const { errors, setFieldValue } = formik;
					console.log(
						'first',
						Object?.keys(errors)?.length === 0 && errors?.constructor === Object
					);
					console.log('values', formik?.values);
					console.log('errors', errors);
					return (
						<Form>
							<Box
								mt={2}
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								}}>
								<div
									style={{
										background: 'rgba(237, 240, 233, 1)',
										padding: 12,
										borderTopLeftRadius: 5,
										borderTopRightRadius: 5,
									}}>
									Contact Information
								</div>
								<Grid
									container
									style={{ marginTop: 5, padding: 10 }}
									spacing={4}>
									<Grid item xs={12} lg={6}>
										<Field
											name='email'
											type='text'
											placeHolder='Email'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='phone'
											type='text'
											placeHolder='Phone'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.phone
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.phone ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.phone}
											</span>
										) : null}
									</Grid>
								</Grid>
							</Box>
							<div
								style={{
									background: 'rgba(237, 240, 233, 1)',
									padding: 12,
									borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									marginTop: 20,
								}}>
								Traveller Details
							</div>
							<Box
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
									padding: 20,
								}}>
								<FieldArray
									name='adult'
									render={(arrayHelpers) => {
										console.log('arrayHelpers', arrayHelpers);
										return (
											<div>
												{formik.values.adult.map((adult, index) =>
													Array.from(
														Array(
															index === 0
																? adultH[0]
																: index === 1
																? adultH[1]
																: index === 2
																? adultH[2]
																: adultH[3]
														)
													).map((i, ind) => {
														return (
															<>
																<Box
																	style={{
																		marginTop: 20,
																		border:
																			'1.33px solid rgba(203, 203, 203, 1)',
																		padding: 20,
																		borderRadius: 5,
																	}}>
																	<div>{`Enter the Detail of Room.${
																		index + 1
																	}, Adult ${ind + 1}`}</div>
																	<Grid
																		container
																		style={{ padding: 10 }}
																		spacing={4}>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.${ind}.title`}
																				as='select'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1)'
																							: errors?.adult
																							? errors?.adult[index][ind]?.title
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1)'
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}>
																				{title.map((item, index) => (
																					<option value={item}>{item}</option>
																				))}
																			</Field>
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.title ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.title}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.${ind}.firstname`}
																				type='text'
																				placeHolder='First Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						'1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`adult.${index}.${ind}.lastname`}
																				type='text'
																				placeHolder='Last Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						'1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																	</Grid>
																</Box>
															</>
														);
													})
												)}
											</div>
										);
									}}
								/>
								<Divider />
								<FieldArray
									name='child'
									render={(arrayHelpers) => {
										console.log('arrayHelpers', arrayHelpers);
										return (
											<div>
												{formik.values.child.map((child, index) =>
													Array.from(
														Array(
															index === 0
																? childH[0]
																: index === 1
																? childH[1]
																: index === 2
																? childH[2]
																: childH[3]
														)
													).map((i, ind) => {
														return (
															<>
																<Box
																	style={{
																		marginTop: 20,
																		border:
																			'1.33px solid rgba(203, 203, 203, 1)',
																		padding: 20,
																		borderRadius: 5,
																	}}>
																	<div>{`Enter the Detail of Room.${
																		index + 1
																	}, Child ${ind + 1}`}</div>
																	<Grid
																		container
																		style={{ padding: 10 }}
																		spacing={4}>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.${ind}.title`}
																				as='select'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object
																							? '1.33px solid rgba(203, 203, 203, 1)'
																							: errors?.child
																							? errors?.child[index][ind]?.title
																								? '2px solid red'
																								: '1.33px solid rgba(203, 203, 203, 1)'
																							: '1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}>
																				{title.map((item, index) => (
																					<option value={item}>{item}</option>
																				))}
																			</Field>
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.title ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.title}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.${ind}.firstname`}
																				type='text'
																				placeHolder='First Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						'1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																		<Grid item xs={12} lg={6}>
																			<Field
																				name={`child.${index}.${ind}.lastname`}
																				type='text'
																				placeHolder='Last Name'
																				style={{
																					padding: 10,
																					width: '100%',
																					borderRadius: 5,
																					border:
																						'1.33px solid rgba(203, 203, 203, 1)',
																				}}
																				inputProps={{
																					style: { padding: 12 },
																				}}
																			/>
																			<br />
																			{/* {Object?.keys(errors)?.length === 0 &&
																			errors?.constructor === Object ? (
																				''
																			) : errors?.adult ? (
																				errors?.adult[index]?.firstname ? (
																					<span
																						style={{
																							color: 'red',
																							fontSize: 12,
																						}}>
																						{errors?.adult[index]?.firstname}
																					</span>
																				) : null
																			) : (
																				''
																			)} */}
																		</Grid>
																	</Grid>
																</Box>
															</>
														);
													})
												)}
											</div>
										);
									}}
								/>
								<Divider />
							</Box>
							<Grid container>
								<Grid item xs={3}></Grid>
								<Grid item xs={12} lg={6}>
									<Button
										type='submit'
										style={{
											background: 'rgb(12 163 153)',
											color: 'white',
											width: '100%',
											marginTop: 30,
											padding: 10,
											borderRadius: 12,
										}}>
										Continue
									</Button>
								</Grid>
								<Grid item xs={3}></Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default HotelSummaryForm;
