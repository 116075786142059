import React, { useState, useEffect } from 'react';
import {
	Box,
	Skeleton,
	Grid,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

const FilterSection = ({ setResult, SetRule }) => {
	const { loading, tbo } = useSelector((state) => state.tbo);
	const [airline, setairline] = useState('');
	const [airlineT, setAirineT] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const [stop, setStop] = useState('');
	const [time, setTime] = useState('');
	useEffect(() => {
		setairline('');
		setRefund(false);
		setNRefund(false);
		setStop('');
		setTime('');
	}, []);
	let filterAirlineTBO = [];
	if (loading) {
		console.log('loading...');
	} else {
		if (
			tbo &&
			tbo?.result &&
			tbo?.result?.Response &&
			tbo?.result?.Response?.Results[0]?.length > 0
		) {
			filterAirlineTBO = [
				...new Set(
					tbo &&
						tbo?.result &&
						tbo?.result?.Response &&
						tbo?.result?.Response?.Results[0].length > 0 &&
						tbo?.result?.Response?.Results[0].map(
							(item) =>
								`${item.Segments[0][0]?.Airline?.AirlineCode}-${item.Segments[0][0]?.Airline?.AirlineName}`
						)
				),
			];
		}
	}
	const handleAirline = (event) => {
		SetRule(event.target.checked);
		setairline(event.target.name);
		if (airline === event.target.name) {
			setResult([]);
			setairline('');
		} else {
			setNRefund(false);
			setRefund(false);
			setStop('');
			setTime('');
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first =
						a.Supplier === 'TBO'
							? Number(
									Number(Math.round(a.Fare.Total_Amount)) -
										Number(Math.round(a.Fare.MFB_Discount))
							  )
							: '';
					let second =
						b.Supplier === 'TBO'
							? Number(
									Number(Math.round(b.Fare.Total_Amount)) -
										Number(Math.round(b.Fare.MFB_Discount))
							  )
							: '';
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareR = (event) => {
		setRefund(event.target.checked);
		SetRule(event.target.checked);
		if (refund) {
			setResult([]);
		} else {
			setNRefund(false);
			setStop('');
			setTime('');
			setairline('');
			setResult([]);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.IsRefundable) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareN = (event) => {
		setNRefund(event.target.checked);
		SetRule(event.target.checked);
		if (nrefund) {
			setResult([]);
		} else {
			setRefund(false);
			setStop('');
			setTime('');
			setairline('');
			setResult([]);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleStops = (val) => {
		SetRule(true);
		setStop(val);
		if (!val) {
			setResult([]);
			SetRule(false);
		} else {
			setNRefund(false);
			setRefund(false);
			setTime('');
			setairline('');
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === parseInt(val)) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first =
						a.Supplier === 'TBO'
							? Number(
									Number(Math.round(a.Fare.Total_Amount)) -
										Number(Math.round(a.Fare.MFB_Discount))
							  )
							: '';
					let second =
						b.Supplier === 'TBO'
							? Number(
									Number(Math.round(b.Fare.Total_Amount)) -
										Number(Math.round(b.Fare.MFB_Discount))
							  )
							: '';
					return first - second;
				});
			console.log('filterCombineStop', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleTiming = (val) => {
		setTime(val);
		if (!val) {
			setResult([]);
		} else {
		}
	};
	console.log('filterAirlineTBO', filterAirlineTBO);
	console.log('tbo', tbo);
	return (
		<>
			<Box
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
					padding: 20,
					borderRadius: 15,
				}}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					{loading ? (
						<Skeleton animation='wave' width='80px' height='40px' />
					) : (
						<h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Filters</h1>
					)}
					{loading ? (
						<Skeleton animation='wave' width='80px' height='40px' />
					) : (
						<h1>Result All</h1>
					)}
				</Box>
				{loading ? (
					<Skeleton animation='wave' width='80px' />
				) : (
					<h1>showing {tbo?.result?.Response?.Results[0].length} Flights</h1>
				)}
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				{/* {loading ? (
					<Skeleton animation='wave' width='80px' />
				) : (
					<h1>Departure</h1>
				)}
				<Grid container spacing={2} style={{ marginTop: 10 }}>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									time === '1' ? handleTiming('') : handleTiming('1')
								}
								style={{
									backgroundColor: time === '1' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '1' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								Before 6AM
							</Box>
						)}
					</Grid>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									time === '2' ? handleTiming('') : handleTiming('2')
								}
								style={{
									backgroundColor: time === '2' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '2' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								6AM - 12PM
							</Box>
						)}
					</Grid>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									time === '3' ? handleTiming('') : handleTiming('3')
								}
								style={{
									backgroundColor: time === '3' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '3' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								12PM - 6PM
							</Box>
						)}
					</Grid>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									time === '4' ? handleTiming('') : handleTiming('4')
								}
								style={{
									backgroundColor: time === '4' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '4' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								After 6PM
							</Box>
						)}
					</Grid>
				</Grid> */}
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				{loading ? <Skeleton animation='wave' width='80px' /> : <h1>Stops</h1>}
				<Grid container spacing={2} style={{ marginTop: 10 }}>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									stop === '1' ? handleStops('') : handleStops('1')
								}
								style={{
									backgroundColor:
										stop === '1' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: stop === '1' ? '#FBFBFB' : '',
									cursor: 'pointer',
									borderRadius: 3,
								}}>
								Direct
							</Box>
						)}
					</Grid>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									stop === '2' ? handleStops('') : handleStops('2')
								}
								style={{
									backgroundColor:
										stop === '2' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: stop === '2' ? '#FBFBFB' : '',
									cursor: 'pointer',
									borderRadius: 3,
								}}>
								1 Stop
							</Box>
						)}
					</Grid>
					<Grid item xs={6}>
						{loading ? (
							<Skeleton variant='rounded' width={120} height={45} />
						) : (
							<Box
								onClick={() =>
									stop === '3' ? handleStops('') : handleStops('3')
								}
								style={{
									backgroundColor:
										stop === '3' ? 'rgb(12, 163, 153)' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									cursor: 'pointer',
									color: stop === '3' ? '#FBFBFB' : '',
									borderRadius: 3,
								}}>
								2+ Stops
							</Box>
						)}
					</Grid>
				</Grid>
				{/* <Divider style={{ border: '1.33px solid #CBCBCB' }} /> */}
				{/* {loading ? <Skeleton animation='wave' width='80px' /> : <h1>Price</h1>} */}
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				{loading ? (
					<Skeleton animation='wave' width='80px' />
				) : (
					<h1>Preferred Airline</h1>
				)}
				<FormGroup style={{ marginTop: 10 }}>
					{loading ? (
						<Skeleton animation='wave' width='120px' />
					) : airlineT ? (
						filterAirlineTBO.map((i, index) => {
							return (
								<FormControlLabel
									control={
										<Checkbox
											checked={airline === i.split('-')[0] ? true : false}
											onChange={handleAirline}
											name={`${i.split('-')[0]}`}
										/>
									}
									label={
										<>
											<Box style={{ display: 'flex' }}>
												<img
													style={{
														marginLeft: 5,
														borderRadius: 5,
														resize: 'contain',
													}}
													width='25px'
													height='25px'
													src={`https://nitish.musafirbazar.com/static/media/${
														i.split('-')[0]
													}.gif`}
												/>
												<span
													style={{
														color: 'rgba(29, 29, 29, 1)',
														marginLeft: 10,
													}}>
													{i.split('-')[1]}
												</span>
											</Box>
										</>
									}
								/>
							);
						})
					) : (
						filterAirlineTBO.map((i, index) => {
							return index < 6 ? (
								<FormControlLabel
									control={
										<Checkbox
											checked={airline === i.split('-')[0] ? true : false}
											onChange={handleAirline}
											name={`${i.split('-')[0]}`}
										/>
									}
									label={
										<>
											<Box style={{ display: 'flex' }}>
												<img
													style={{
														marginLeft: 5,
														borderRadius: 5,
														resize: 'contain',
													}}
													width='25px'
													height='25px'
													src={`https://nitish.musafirbazar.com/static/media/${
														i.split('-')[0]
													}.gif`}
												/>
												<span
													style={{
														color: 'rgba(29, 29, 29, 1)',
														marginLeft: 10,
													}}>
													{i.split('-')[1]}
												</span>
											</Box>
										</>
									}
								/>
							) : (
								''
							);
						})
					)}
				</FormGroup>
				{airlineT ? (
					''
				) : (
					<span
						onClick={() => (airlineT ? setAirineT(false) : setAirineT(true))}>
						{filterAirlineTBO.length > 0 && filterAirlineTBO.length > 6
							? `+
										${Number(Number(filterAirlineTBO.length) - Number(6))} more`
							: ''}
					</span>
				)}
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				{loading ? (
					<Skeleton animation='wave' width='80px' />
				) : (
					<h1>Fare Type</h1>
				)}
				<FormGroup style={{ marginTop: 5 }}>
					{loading ? (
						<Skeleton animation='wave' width='120px' />
					) : (
						<FormControlLabel
							control={
								<Checkbox
									checked={refund}
									onChange={handleFareR}
									name='Refundable'
								/>
							}
							label={
								<>
									<Box style={{ display: 'flex' }}>
										<span
											style={{
												color: 'rgba(29, 29, 29, 1)',
												marginLeft: 10,
											}}>
											Refundable
										</span>
									</Box>
								</>
							}
						/>
					)}
					{loading ? (
						<Skeleton animation='wave' width='120px' />
					) : (
						<FormControlLabel
							control={
								<Checkbox
									checked={nrefund}
									onChange={handleFareN}
									name='NonRefundable'
								/>
							}
							label={
								<>
									<Box style={{ display: 'flex' }}>
										<span
											style={{
												color: 'rgba(29, 29, 29, 1)',
												marginLeft: 10,
											}}>
											Non-Refundable
										</span>
									</Box>
								</>
							}
						/>
					)}
				</FormGroup>
			</Box>
		</>
	);
};

export default FilterSection;
