import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentbookinghistoryDispatch } from '../../reducers/AgentReducer';
import TranTable from './TransTable';
import BookingTable from './BookingTable';
import LoaderModal from './LoaderModal';
const BookingHistory = () => {
	const dispatch = useDispatch();
	const { agentInfo, loading } = useSelector((state) => state.agent);
	useEffect(() => {
		dispatch(agentbookinghistoryDispatch(agentInfo?.result?.result?.id));
	}, []);
	return (
		<>
			<Box mt={3} ml={3}>
				<h1 style={{ fontSize: 20, fontWeight: 'bold' }}>Booking Details</h1>
				<BookingTable />
			</Box>
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default BookingHistory;
