import React from 'react';
import { Box, Button, Divider, Grid } from '@mui/material';
import Line from '../../assets/images/Line.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
	removeSelectedSeatRow,
	setBoardingPoint,
	setBusId,
	setBusTab,
	setDropingPoint,
	setSelectedSeat,
} from '../../reducers/UiReducer';
import {
	fetchSeatLayoutDispatch,
	seatLayoutSuccess,
} from '../../reducers/BusReducer';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const BusRow = ({ item }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { busId, busSeat, busTab, boardingPoint, dropingPoint } = useSelector(
		(state) => state.ui
	);
	const { loading, seatLayout, loadingseat, busOrderId } = useSelector(
		(state) => state.bus
	);
	function timeConvertbus(a) {
		let arhour = Math.floor(a / 60);
		if (arhour > 24) {
			arhour = arhour - 24;
		}
		let armin = a % 60;
		let format = `${arhour}:${armin === 0 ? '00' : armin}:00`;
		function formatTime(timeString) {
			const [hourString, minute] = timeString.split(':');
			const hour = +hourString % 24;
			return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
		}
		return formatTime(format);
	}
	let UPPERSEAT = [];
	let UPPERSEATW1 = [];
	let UPPERSEATW2 = [];
	let LOWERSEAT = [];
	let LOWERSEATW1 = [];
	let LOWERSEATW2 = [];
	let LOWERROW0 = [];
	let LOWERROW1 = [];
	let LOWERROW2 = [];
	let LOWERROW3 = [];
	let LOWERROW4 = [];
	let LOWERROW5 = [];
	let UPPERROW0 = [];
	let UPPERROW1 = [];
	let UPPERROW2 = [];
	let UPPERROW3 = [];
	let UPPERROW4 = [];
	let UPPERROW5 = [];

	// Step 1 Start
	if (loading) {
		console.log('loading....');
	} else if (
		seatLayout &&
		seatLayout.result &&
		seatLayout?.result?.seats?.length > 0
	) {
		seatLayout &&
			seatLayout.result &&
			seatLayout?.result?.seats.map((item) => {
				if (item.zIndex === '0') {
					LOWERSEAT.push({
						...item,
					});
				}
				if (item.zIndex === '2') {
					LOWERSEAT.push({
						...item,
					});
				}
				if (item.zIndex === '1') {
					UPPERSEAT.push({
						...item,
					});
				}
			});
	}
	console.log('LOWERSEAT', LOWERSEAT);
	console.log('UPPERSEAT', UPPERSEAT);

	// Step1 End

	// Step2 Start

	if (UPPERSEAT && UPPERSEAT.length > 0) {
		UPPERSEAT.map((item) => {
			if (item.width === '1') {
				UPPERSEATW1.push({
					...item,
				});
			}
			if (item.width === '2') {
				UPPERSEATW2.push({
					...item,
				});
			}
		});
	}
	if (LOWERSEAT && LOWERSEAT.length > 0) {
		LOWERSEAT.map((item) => {
			if (item.width === '1') {
				LOWERSEATW1.push({
					...item,
				});
			}
			if (item.width === '2') {
				LOWERSEATW2.push({
					...item,
				});
			}
		});
	}
	if (LOWERSEAT && LOWERSEAT?.length > 0) {
		LOWERSEAT.map((item) => {
			if (item.row === '0') {
				LOWERROW0.push({
					...item,
				});
			}
			if (item.row === '1') {
				LOWERROW1.push({
					...item,
				});
			}
			if (item.row === '2') {
				LOWERROW2.push({
					...item,
				});
			}
			if (item.row === '3') {
				LOWERROW3.push({
					...item,
				});
			}
			if (item.row === '4') {
				LOWERROW4.push({
					...item,
				});
			}
			if (item.row === '5') {
				LOWERROW5.push({
					...item,
				});
			}
		});
	}
	if (UPPERSEAT && UPPERSEAT?.length > 0) {
		UPPERSEAT.map((item) => {
			if (item.row === '0') {
				UPPERROW0.push({
					...item,
				});
			}
			if (item.row === '1') {
				UPPERROW1.push({
					...item,
				});
			}
			if (item.row === '2') {
				UPPERROW2.push({
					...item,
				});
			}
			if (item.row === '3') {
				UPPERROW3.push({
					...item,
				});
			}
			if (item.row === '4') {
				UPPERROW4.push({
					...item,
				});
			}
			if (item.row === '5') {
				UPPERROW5.push({
					...item,
				});
			}
		});
	}

	// Step2 End

	console.log('LOWERROW0', LOWERROW0);

	//Filter
	let FILLOWERROW0 = [];
	if (LOWERROW0?.length > 0) {
		FILLOWERROW0 = LOWERROW0.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILLOWERROW1 = [];
	if (LOWERROW1?.length > 0) {
		FILLOWERROW1 = LOWERROW1.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILLOWERROW2 = [];
	if (LOWERROW2?.length > 0) {
		FILLOWERROW2 = LOWERROW2.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILLOWERROW3 = [];
	if (LOWERROW3?.length > 0) {
		FILLOWERROW3 = LOWERROW3.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILLOWERROW4 = [];
	if (LOWERROW4?.length > 0) {
		FILLOWERROW4 = LOWERROW4.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILLOWERROW5 = [];
	if (LOWERROW5?.length > 0) {
		FILLOWERROW5 = LOWERROW5.sort((a, b) => {
			return a.column - b.column;
		});
	}

	// Upper
	let FILUPPERROW0 = [];
	if (UPPERROW0?.length > 0) {
		FILUPPERROW0 = UPPERROW0.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILUPPERROW1 = [];
	if (UPPERROW1?.length > 0) {
		FILUPPERROW1 = UPPERROW1.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILUPPERROW2 = [];
	if (UPPERROW2?.length > 0) {
		FILUPPERROW2 = UPPERROW2.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILUPPERROW3 = [];
	if (UPPERROW3?.length > 0) {
		FILUPPERROW3 = UPPERROW3.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILUPPERROW4 = [];
	if (UPPERROW4?.length > 0) {
		FILUPPERROW4 = UPPERROW4.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let FILUPPERROW5 = [];
	if (UPPERROW5?.length > 0) {
		FILUPPERROW5 = UPPERROW5.sort((a, b) => {
			return a.column - b.column;
		});
	}
	return (
		<>
			<Box
				mt={2}
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
				}}>
				<Box
					display={'flex'}
					style={{ padding: 25 }}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Box ml={2}>
						<p
							title={item.travels}
							style={{
								fontWeight: 'bold',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '150px',
								height: '1.2em',
								whiteSpace: 'nowrap',
							}}>
							{item.travels}
						</p>
						<p
							title={item.busType}
							style={{
								fontSize: 12,

								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '150px',
								whiteSpace: 'nowrap',
								marginTop: 5,
							}}>
							{item.busType}
						</p>
					</Box>
					<Box>
						<p
							title={timeConvertbus(item.departureTime)}
							style={{
								fontWeight: 'bold',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '95px',
								height: '1.2em',
								whiteSpace: 'nowrap',
							}}>
							{timeConvertbus(item.departureTime)}
						</p>
						<p style={{ fontSize: 12, marginTop: 5 }}>
							{item.doj.split('T')[0]}
						</p>
					</Box>
					<Box textAlign={'center'}>
						<p style={{ fontSize: 14 }}>{item.duration}</p>
						<img src={Line} />
					</Box>{' '}
					<Box>
						<p
							title={timeConvertbus(item.arrivalTime)}
							style={{
								fontWeight: 'bold',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '95px',
								height: '1.2em',
								whiteSpace: 'nowrap',
							}}>
							{timeConvertbus(item.arrivalTime)}
						</p>
						<p style={{ fontSize: 12, marginTop: 5 }}>
							{item.doj.split('T')[0]}
						</p>
					</Box>
					<Box>
						<p
							title={
								Array.isArray(item?.fares)
									? item.fares.map((d) => `${Number(Math.round(d))} / `)
									: Number(Math.round(item.fares))
							}
							style={{
								fontWeight: 'bold',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '100px',
								height: '1.2em',
								whiteSpace: 'nowrap',
								// fontSize: 12,
							}}>
							₹{' '}
							{Array.isArray(item?.fares)
								? item.fares.map((d) => `${Number(Math.round(d))} / `)
								: Number(Math.round(item.fares))}
						</p>
					</Box>
					<Box>
						<p>{item.availableSeats} Seat</p>
					</Box>
				</Box>
				<Box
					display={'flex'}
					style={{ padding: '0px 15px' }}
					justifyContent={'end'}
					alignItems={'center'}>
					<Button
						style={{ fontSize: 12, textTransform: 'capitalize' }}
						onClick={() => {
							dispatch(setBusTab('bdpoint'));
							dispatch(setBusId(item.id));
						}}>
						Boarding / Dropping Points
					</Button>
					{/* <Button style={{ fontSize: 12, textTransform: 'capitalize' }}>
						Cancellation Policy
					</Button> */}
					{/* <Button style={{ fontSize: 12, textTransform: 'capitalize' }}>
						Fare Details
					</Button> */}
					<Button
						style={{
							fontSize: 12,
							textTransform: 'capitalize',
							backgroundColor: 'rgb(12 163 153)',
							color: 'white',
							marginBottom: 5,
						}}
						onClick={() => {
							dispatch(setBusId(item.id));
							dispatch(setBusTab('seat'));
							let trip = {
								tripid: item.id,
							};
							dispatch(fetchSeatLayoutDispatch(trip));
							dispatch(setSelectedSeat(null));
							dispatch(seatLayoutSuccess(null));
						}}>
						Book Seat
					</Button>
				</Box>
				{busId === item.id && busTab === 'seat' && (
					<Box style={{ background: '#f0f0f0', padding: 15 }}>
						{loadingseat && (
							<p style={{ textAlign: 'center' }}>loading......</p>
						)}
						<Grid container spacing={4}>
							<Grid item xs={6}>
								{busSeat?.length > 0 ? (
									<Box display={'flex'} justifyContent={'space-between'}>
										<Box style={{ fontSize: 12 }}>Select Seat(s)</Box>
										<Box style={{ fontSize: 12, fontWeight: 'bold' }}>
											Amount: ₹
											{busSeat &&
												busSeat?.length > 0 &&
												busSeat
													?.map((item) => parseInt(item.totalFare))
													.reduce((prev, next) => prev + next)}
										</Box>
									</Box>
								) : (
									''
								)}
								{UPPERSEAT?.length > 0 && (
									<Box
										mt={2}
										style={{
											background: 'white',
											padding: 10,
										}}>
										<h1>Upper Desk</h1>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW0.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW1.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW2.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW3.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW4.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILUPPERROW5.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
									</Box>
								)}
								{LOWERSEAT?.length > 0 && (
									<Box
										mt={2}
										style={{
											background: 'white',
											padding: 10,
										}}>
										<h1>Lower Desk</h1>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW0.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW1.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW2.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW3.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW4.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
										<Box display={'flex'} mt={2} justifyContent={'end'}>
											{FILLOWERROW5.map((i) => {
												return (
													<Box
														onClick={() =>
															i.available === 'true'
																? busSeat.find((user) => user.name === i.name)
																	? dispatch(removeSelectedSeatRow(i))
																	: dispatch(setSelectedSeat(i))
																: ''
														}
														title={`Seat No: ${i.name} | Fare: ${i.totalFare}`}
														style={{
															border: '1px solid grey',
															padding: '0px 4px',
															borderRadius: 2,
															cursor:
																i.available === 'true'
																	? 'pointer'
																	: 'not-allowed',
															fontSize: 12,
															marginLeft: 8,

															textOverflow: 'ellipsis',
															overflow: 'hidden',
															width: '32px',
															whiteSpace: 'nowrap',
															color: busSeat.find(
																(user) => user.name === i.name
															)
																? 'white'
																: '',
															background: busSeat.find(
																(user) => user.name === i.name
															)
																? 'green'
																: i.ladiesSeat === 'false'
																? i.available === 'true'
																	? ''
																	: 'lightgrey'
																: 'pink',
														}}>
														{i.name}
													</Box>
												);
											})}
										</Box>
									</Box>
								)}
								{loadingseat ? (
									''
								) : (
									<Box display={'flex'} mt={2} alignItems={'center'}>
										<Box display={'flex'} alignItems={'center'}>
											<Box
												style={{
													border: '1px solid lightgrey',
													background: 'white',
													padding: 2,
													fontSize: 12,
													borderRadius: 3,
												}}>
												D5
											</Box>
											<span style={{ fontSize: 12, marginLeft: 5 }}>
												Available seat
											</span>
										</Box>
										<Box display={'flex'} alignItems={'center'} ml={2}>
											<Box
												style={{
													border: '1px solid lightgrey',
													background: 'lightgrey',
													padding: 2,
													fontSize: 12,
													borderRadius: 3,
												}}>
												D5
											</Box>
											<span style={{ fontSize: 12, marginLeft: 5 }}>
												Unavailable seat
											</span>
										</Box>
										<Box display={'flex'} alignItems={'center'} ml={2}>
											<Box
												style={{
													border: '1px solid lightgrey',
													background: 'pink',
													padding: 2,
													fontSize: 12,
													borderRadius: 3,
												}}>
												D5
											</Box>
											<span style={{ fontSize: 12, marginLeft: 5 }}>
												Reserved(Ladies)
											</span>
										</Box>
									</Box>
								)}
							</Grid>
							<Grid item xs={6}>
								{busSeat?.length > 0 ? (
									<>
										<Box>
											<p>Select Boarding and Droping Points</p>
											<Divider style={{ border: '2px solid lightgrey' }} />
											<Grid container>
												<Grid item xs={6}>
													<p style={{ fontSize: 14, marginTop: 10 }}>
														Boarding Points
													</p>
													<FormControl style={{ marginTop: 10 }}>
														<RadioGroup
															aria-labelledby='demo-radio-buttons-group-label'
															defaultValue=''
															name='radio-buttons-group'>
															{Array.isArray(item?.boardingTimes) ? (
																item.boardingTimes.map((b) => {
																	return (
																		<FormControlLabel
																			value={b.bpName}
																			onClick={() =>
																				dispatch(setBoardingPoint(b))
																			}
																			control={<Radio size='small' />}
																			label={
																				<>
																					<p style={{ fontSize: 12 }}>
																						{b.bpName}
																					</p>
																				</>
																			}
																		/>
																	);
																})
															) : (
																<FormControlLabel
																	value={item.boardingTimes.bpName}
																	onClick={() =>
																		dispatch(
																			setBoardingPoint(item.boardingTimes)
																		)
																	}
																	control={<Radio size='small' />}
																	label={
																		<>
																			<p style={{ fontSize: 12 }}>
																				{item.boardingTimes.bpName}
																			</p>
																		</>
																	}
																/>
															)}
														</RadioGroup>
													</FormControl>
												</Grid>
												<Grid item xs={6}>
													<p style={{ fontSize: 14, marginTop: 10 }}>
														Droping Points
													</p>
													<FormControl style={{ marginTop: 10 }}>
														<RadioGroup
															aria-labelledby='demo-radio-buttons-group-label'
															defaultValue=''
															name='radio-buttons-group'>
															{Array.isArray(item?.droppingTimes) ? (
																item.droppingTimes.map((b) => {
																	return (
																		<FormControlLabel
																			value={b.bpName}
																			onClick={() =>
																				dispatch(setDropingPoint(b))
																			}
																			control={<Radio size='small' />}
																			label={
																				<>
																					<p style={{ fontSize: 12 }}>
																						{b.bpName}
																					</p>
																				</>
																			}
																		/>
																	);
																})
															) : (
																<FormControlLabel
																	value={item.droppingTimes.bpName}
																	onClick={() =>
																		dispatch(
																			setDropingPoint(item.droppingTimes)
																		)
																	}
																	control={<Radio size='small' />}
																	label={
																		<>
																			<p style={{ fontSize: 12 }}>
																				{item.droppingTimes.bpName}
																			</p>
																		</>
																	}
																/>
															)}
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</Box>
										<Button
											onClick={() =>
												navigate('/busreiew', {
													state: {
														seat: busSeat,
														formData: location.state,
														busDetail: item,
														boardingPoint: boardingPoint,
														dropingPoint: dropingPoint,
														orderId: busOrderId?.result,
													},
												})
											}
											style={{
												backgroundColor: 'rgb(12 163 153)',
												color: 'white',
												textTransform: 'capitalize',
												marginTop: 25,
											}}>
											Continue
										</Button>
									</>
								) : (
									<Box style={{ textAlign: 'center', padding: 20 }}>
										<p>Select seats to continue</p>
									</Box>
								)}
							</Grid>
						</Grid>
					</Box>
				)}
				{busTab === 'bdpoint' && busId === item.id && (
					<Box style={{ background: '#f0f0f0', padding: 25 }}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Box>
									<h1 style={{ fontWeight: 'bold' }}>Boarding Points</h1>
									{Array.isArray(item?.boardingTimes) ? (
										<>
											{item?.boardingTimes?.map((i, index) => {
												return (
													<li
														style={{
															fontSize: 12,
															marginTop: 10,
														}}>{`${i.bpName}`}</li>
												);
											})}
										</>
									) : (
										<li
											style={{
												fontSize: 12,
												marginTop: 10,
											}}>{` ${item.boardingTimes.bpName}`}</li>
									)}
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box>
									<h1 style={{ fontWeight: 'bold' }}>Droping Points</h1>
									{Array.isArray(item?.droppingTimes) ? (
										<>
											{item?.droppingTimes?.map((i, index) => {
												return (
													<li
														style={{
															fontSize: 12,
															marginTop: 10,
														}}>{`${i.bpName}`}</li>
												);
											})}
										</>
									) : (
										<li
											style={{
												fontSize: 12,
												marginTop: 10,
											}}>{` ${item.droppingTimes.bpName}`}</li>
									)}
								</Box>
							</Grid>
							<Grid item xs={4}></Grid>
						</Grid>
					</Box>
				)}
			</Box>
		</>
	);
};

export default BusRow;
