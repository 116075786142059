import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useNavigate } from 'react-router-dom';

export default function BookingBusTable() {
	const { busHistory, loading } = useSelector((state) => state.agent);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const columns = [
		{ field: 'orderId', headerName: 'OrderId', width: 150 },
		{ field: 'departureCity', headerName: 'Origin', width: 150 },
		{ field: 'arrivalCity', headerName: 'Destination', width: 150 },
		{ field: 'journeyType', headerName: 'Journey Type', width: 150 },
		{ field: 'dob', headerName: 'Date of Booking', width: 150 },
		{ field: 'doj', headerName: 'Travel Date', width: 150 },
		{ field: 'payableAmount', headerName: 'Ticket Cost', width: 150 },
		{
			field: 'bookingStatus',
			headerName: 'Booking Status',
			width: 200,
			editable: true,
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: 10,
						}}>
						<Button
							onClick={() =>
								navigate('/bookingdetails', { state: cellValues.row })
							}
							variant='contained'
							color='primary'
							style={{ marginBottom: 10, marginRight: 5 }}>
							View
						</Button>
						<a
							href={`https://s.marinersforex.com/api/home/downloadticketbus/${cellValues.row.orderId}`}>
							<Button
								variant='contained'
								color='primary'
								style={{ marginBottom: 10 }}
								startIcon={<CloudDownloadIcon />}>
								Download
							</Button>
						</a>
					</Box>
				);
			},
		},
	];
	return (
		<Box sx={{ height: '100%', width: '100%', marginTop: 5 }}>
			{busHistory && busHistory?.result.buses?.length > 0 ? (
				<DataGrid
					rows={busHistory?.result?.buses}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					// checkboxSelection
					disableRowSelectionOnClick
				/>
			) : (
				<p>No Record Found</p>
			)}
		</Box>
	);
}
