import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import logo from '../assets/images/logo.png';

const DomesticFlight = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					Domestic Flight
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<p>
							Domestic flights to India s major cities are available at a low
							cost
						</p>
						<br />{' '}
						<p>
							The World Wide Web s vast network has impacted every aspect of our
							lives and made everything easier for consumers. Due to the
							shifting circumstances, the travel and tour sector has grown into
							a thriving enterprise. From purchasing airline tickets to
							obtaining on-board passes for flights, the internet has made
							things very convenient for us. When it comes to booking domestic
							flight tickets, internet travel sites like Mariners Forex can
							provide you with affordable travel options. Mariners Forex
							features a large database of flights to cities throughout the
							Country. You can find the cheapest ticket here for flights to
							Delhi, Goa, Mumbai, Chennai, Kolkata, Bangalore, and many other
							Indian destinations.
						</p>
						{Array.from(Array(20)).map((i) => {
							return (
								<>
									<br />
									<p>
										<strong>
											Popular Domestic Flight Destinations from Chandigarh (IXC)
										</strong>
									</p>
									<br />
									<p>
										Delhi Mumbai Bangalore Goa Ahmedabad Srinagar Pune Hyderabad
										Jaipur Jammu
									</p>
									<br />
								</>
							);
						})}
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default DomesticFlight;
