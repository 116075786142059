import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import ExploreWorld from '../components/resuableComponents/ExploreWorld';
import Activities from '../components/resuableComponents/Activities';
import TopDest from '../components/resuableComponents/TopDest';
import BottomBanner from '../components/resuableComponents/BottomBanner';
import Package from '../components/resuableComponents/Package';
import HotelSlider from '../components/resuableComponents/HotelSlider';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedHeaderTab } from '../reducers/UiReducer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';

const HotelHomePage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.pathname === '/hotel') {
			dispatch(setSelectedHeaderTab('hotel'));
		}
	}, []);
	return (
		<>
			<Navbar />
			<MNavbar />
			<HotelSlider />
			<ExploreWorld />
			<Package />
			{/* <Activities /> */}
			{/* <Adventure /> */}
			<TopDest />
			<BottomBanner />
			<Footer />
		</>
	);
};

export default HotelHomePage;
