import React from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';

const FailedPage = () => {
	return (
		<>
			<Navbar />
			<div style={{ marginTop: 146, textAlign: 'center', marginBottom: 150 }}>
				<h3>!! Transaction Failed !!</h3>
			</div>
			<Footer />
		</>
	);
};

export default FailedPage;
