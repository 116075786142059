import { axiosInstance } from '../Axios';
import { storeData } from './UiReducer';

const { createSlice } = require('@reduxjs/toolkit');

const HotelReducer = createSlice({
	name: 'hoteltbo',
	initialState: {
		success: false,
		error: null,
		hotelCity: null,
		loadinghotel: false,
		addHotel: null,
		hotelResult: null,
		hotelInfo: null,
		roomInfo: null,
		roomBlock: null,
		debitHotel: null,
	},
	reducers: {
		cityHotelRequest(state, action) {
			// state.loadinghotel = true;
		},
		cityHotelSuccess(state, action) {
			// state.loadinghotel = false;
			state.hotelCity = action.payload;
			storeData('hotelCity', action.payload);
			state.error = null;
		},
		cityHotelFail(state, action) {
			// state.loadinghotel = false;
			state.error = action.payload;
		},
		addhotelRequest(state, action) {
			state.loadinghotel = true;
		},
		addhotelSuccess(state, action) {
			state.loadinghotel = false;
			state.addHotel = action.payload;
			state.error = null;
		},
		addhotelFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelSearchRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelSearchSuccess(state, action) {
			state.loadinghotel = false;
			state.hotelResult = action.payload;
			state.error = null;
		},
		hotelSearchFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelinfoRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelinfoSuccess(state, action) {
			state.loadinghotel = false;
			state.hotelInfo = action.payload;
			state.error = null;
		},
		hotelinfoFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelroominfoRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelroominfoSuccess(state, action) {
			state.loadinghotel = false;
			state.roomInfo = action.payload;
			state.error = null;
		},
		hotelroominfoFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelroomblockRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelroomblockSuccess(state, action) {
			state.loadinghotel = false;
			state.roomBlock = action.payload;
			state.error = null;
		},
		hotelroomblockFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		addBookingRequest(state, action) {
			state.loadinghotel = true;
		},
		addBookingSuccess(state, action) {
			state.loadinghotel = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		addBookingFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelbookRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelbookSuccess(state, action) {
			state.loadinghotel = false;
			state.hotelBook = action.payload;
			state.error = null;
		},
		hotelbookFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelbookdetailRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelbookdetailSuccess(state, action) {
			state.loadinghotel = false;
			state.hotelBook = action.payload;
			state.error = null;
		},
		hotelbookdetailFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		debitRequest(state, action) {
			state.loadinghotel = true;
		},
		debitSuccess(state, action) {
			state.loadinghotel = false;
			state.debitHotel = action.payload;
			state.error = null;
		},
		debitFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HotelReducer;
export const {
	cityHotelRequest,
	cityHotelSuccess,
	cityHotelFail,
	addhotelRequest,
	addhotelSuccess,
	addhotelFail,
	hotelSearchRequest,
	hotelSearchSuccess,
	hotelSearchFail,
	hotelinfoRequest,
	hotelinfoSuccess,
	hotelinfoFail,
	hotelroominfoRequest,
	hotelroominfoSuccess,
	hotelroominfoFail,
	hotelroomblockRequest,
	hotelroomblockSuccess,
	hotelroomblockFail,
	addBookingRequest,
	addBookingSuccess,
	addBookingFail,
	hotelbookRequest,
	hotelbookSuccess,
	hotelbookFail,
	hotelbookdetailRequest,
	hotelbookdetailSuccess,
	hotelbookdetailFail,
	debitRequest,
	debitSuccess,
	debitFail,
} = actions;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjO7hqe3PG9easyge==',
	},
};
export const fetchHotelCityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityHotelRequest());
		const { data } = await axiosInstance.post(
			'home/hotelcity',
			bodyData,
			config
		);
		console.log(data);
		dispatch(cityHotelSuccess(data));
	} catch (error) {
		dispatch(
			cityHotelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addhotelSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelSearchSuccess(null));
		dispatch(addhotelRequest());
		const { data } = await axiosInstance.post(
			'hotel/addsearchhotel',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addhotelSuccess(data));

		if (data.status === 200) {
			dispatch(hotelSearchDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			addhotelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelSearchRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/search',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelSearchSuccess(data));

		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			hotelSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelInfoDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelinfoRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/hotelinfo',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelinfoSuccess(data));

		if (data.status === 200) {
			dispatch(hotelRoomInfoDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			hotelinfoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const hotelRoomInfoDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelroominfoRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/roominfo',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelroominfoSuccess(data));
	} catch (error) {
		dispatch(
			hotelroominfoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const hotelRoomBlockDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelroomblockRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/blockroom',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelroomblockSuccess(data));
	} catch (error) {
		dispatch(
			hotelroomblockFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const hoteladdBookingDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBookingRequest());
		const { data } = await axiosInstance.post(
			'hotel/addbookinghotel',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addBookingSuccess(data));

		// if (data.status === 200) {
		// 	dispatch(
		// 		hotelRoomBlockDispatch(
		// 			formData,
		// 			payment,
		// 			formDataBooking,
		// 			history,
		// 			debit
		// 		)
		// 	);
		// }
	} catch (error) {
		dispatch(
			addBookingFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelBookDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelbookRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/hotelbook',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelbookSuccess(data));

		if (data.status === 200) {
			let formData = {
				bookingId: data.result.BookResult.BookingId,
				orderId: bodyData.orderId,
			};
			// dispatch(hotelBookingDetailDispatch(formData, history));
			// if (payment === 'easebuzz') {
			// 	dispatch(toggleEasebuzzModal(true));
			// }
		}
	} catch (error) {
		dispatch(
			hotelbookFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const hotelBookingDetailDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(hotelbookdetailRequest());
			const { data } = await axiosInstance.post(
				'hoteltbo/bookingdetails',
				bodyData,
				config
			);

			console.log(data);
			dispatch(hotelbookdetailSuccess(data));

			if (data.status === 200) {
				// history.push({
				// 	pathname:'/hotelticket',
				// 	state:{bookingdetail:data.result.GetBookingDetailResult}
				// })
				history.push({
					pathname: '/hotelticket',
					state: { bookingdetailhotel: data.result.GetBookingDetailResult },
				});
			}
		} catch (error) {
			dispatch(
				hotelbookdetailFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const hoteldebitPaymentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitRequest());
		const { data } = await axiosInstance.post(
			'hotel/debithotelpayment',
			bodyData,
			config
		);

		console.log(data);
		dispatch(debitSuccess(data));

		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			debitFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HotelReducer;
