import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import logo from '../assets/images/logo.png';

const InternationalFlight = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					International Flight
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<p>
							International flights to India s major cities are available at a
							low cost
						</p>
						<br />{' '}
						{Array.from(Array(20)).map((i) => {
							return (
								<>
									<br />
									<p>
										<strong>
											Popular International Flight Destinations from Delhi (DEL)
										</strong>
									</p>
									<br />
									<p>
										Bangkok Hong Kong London Kuala Lumpur Kathmandu Singapore
										Abu Dhabi Dubai Muscat Addis Ababa Male Sydney Paris
										Helsinki Doha Tashkent Sharjah Kuwait Vancouver Chicago San
										Francisco Toronto New York Melbourne
									</p>
									<br />
								</>
							);
						})}
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default InternationalFlight;
