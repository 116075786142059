import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTheme, useMediaQuery } from '@mui/material';

const steps = [
	'Hotel Selection',
	'Review & Traveller Details & Add-ons',
	'Payment',
];

export default function StepperHotel({ step }) {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Box sx={{ width: '100%' }}>
			<Stepper
				activeStep={step}
				sx={{
					'& .MuiStepLabel-root .Mui-active': {
						color: 'white',
						fontSize: matches ? 12 : '',
					},
					'& .MuiStepLabel-root .Mui-completed': {
						color: 'white',
						fontSize: matches ? 12 : '',
					},
					'& .MuiStepLabel-root .Mui-completed': {
						color: 'white', // Just text label (COMPLETED)
						fontSize: matches ? 12 : '',
					},
					'& .MuiStepLabel-label .Mui-active': {
						color: 'white',
					},
					'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
						fill: 'black', // circle's number (ACTIVE)
						fontSize: matches ? 12 : '',
					},
				}}>
				{steps.map((label, index) => {
					const labelProps = {};
					return (
						<Step key={label}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Box>
	);
}
