import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalPay } from '../../reducers/UiReducer';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
const PaymentVerify = ({ handleBook, pay, pgCharge }) => {
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const { paymentToggle, depart, arrival } = useSelector((state) => state.ui);
	const location = useLocation();
	const dispatch = useDispatch();
	const showModal = () => {
		setOpen(true);
	};
	const handleOk = () => {
		setLoading(true);
		handleBook(setLoading);
	};
	const handleCancel = () => {
		// setOpen(false);
		dispatch(setModalPay(false));
	};
	console.log('location', location);
	return (
		<>
			{/* <Button type='primary' onClick={showModal}>
				Open Modal with customized footer
			</Button> */}
			<Modal
				open={paymentToggle}
				title='Confirm Booking'
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel} disabled={loading}>
						Cancel
					</Button>,
					<Button
						key='submit'
						type='primary'
						loading={loading}
						onClick={handleOk}>
						Procced
					</Button>,
				]}>
				<Divider />
				<Box display='flex' justifyContent='space-around'>
					<p>ORDER ID: </p>
					<span style={{ fontWeight: 'bold' }}>{location.state.orderId}</span>
				</Box>
				<Box display='flex' justifyContent='space-around' mt={2} mb={3}>
					<p>PAYABLE AMOUNT:</p>
					<span>
						<span style={{ fontWeight: 'bold' }}>
							₹
							{location.state.formData.JourneyType === 1
								? Number(
										Math.round(
											Number(location.state.item.Fare.Total_Amount) -
												Number(location.state.item.Fare.MFB_Discount)
										) + Number(pay === 'ONLINE' ? pgCharge : 0)
								  )
								: location.state.formData.JourneyType === 2
								? depart.country_code === 'IN' && arrival.country_code === 'IN'
									? Number(
											Math.round(
												Number(
													Number(
														location.state.onwardFlight.Fare.Total_Amount
													) -
														Number(
															location.state.onwardFlight.Fare.MFB_Discount
														)
												) +
													Number(
														Number(
															location.state.returnFlight.Fare.Total_Amount
														) -
															Number(
																location.state.returnFlight.Fare.MFB_Discount
															)
													) +
													Number(pay === 'ONLINE' ? pgCharge : 0)
											)
									  )
									: Number(
											Math.round(
												Number(location.state.item.Fare.Total_Amount) -
													Number(location.state.item.Fare.MFB_Discount) +
													Number(pay === 'ONLINE' ? pgCharge : 0)
											)
									  )
								: ''}
						</span>
					</span>
				</Box>

				{/* <p>Some contents...</p>
				<p>Some contents...</p>
				<p>Some contents...</p> */}
			</Modal>
		</>
	);
};
export default PaymentVerify;
