import React from 'react';
import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProfileForm from '../../forms/ProfileForm';

const Profile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {}, []);
	return (
		<div>
			<Box mt={3} style={{ padding: 20 }}>
				<h2>Profile Setting</h2>
				<Divider style={{ marginTop: 20 }} />
				<ProfileForm />
			</Box>
		</div>
	);
};

export default Profile;
