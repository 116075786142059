import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Line from '../../assets/images/Line.svg';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../assets/images/cross.svg';
import Skeleton from '@mui/material/Skeleton';
import { TBOFlightFareRuleDispatch } from '../../reducers/TBOReducer';
import { setReturn, setReturnRow } from '../../reducers/UiReducer';

const FlightRoundTripRowReturn = ({ item }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [data, setData] = useState('');
	const [toggle, setToggle] = useState('one');
	const handleToggle = (val) => {
		setToggle(val);
	};
	// const { loading } = useSelector((state) => state.home);
	const { tbo, orderId, fareRule, loading, loadingRule } = useSelector(
		(state) => state.tbo
	);
	const { returnToggle } = useSelector((state) => state.ui);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const handleData = (val) => {
		if (data === val) {
			setData('');
		} else {
			setData(val);
		}
	};
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	console.log('trace', tbo?.result?.Response.TraceId);
	return (
		<>
			<Box
				onClick={() => {
					dispatch(setReturnRow(item.ResultIndex));
					dispatch(setReturn(item));
				}}
				mt={2}
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
					background:
						returnToggle === item.ResultIndex ? 'rgb(241, 250, 239)' : '',
					cursor: 'pointer',
				}}>
				<Box
					display={'flex'}
					style={{ padding: 25 }}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Box display={'flex'} alignItems={'center'}>
						<img
							src={`https://nitish.musafirbazar.com/static/media/${
								item.Supplier === 'TBO' ? item.AirlineCode : ''
							}.gif`}
							style={{ borderRadius: 5, height: 25 }}
						/>
						<Box ml={1} mt={2}>
							<p
								title={
									item.Supplier === 'TBO'
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Airline.AirlineName;
										  })
										: ''
								}
								style={{
									fontWeight: 'bold',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									width: '50px',
									height: '1.2em',
									whiteSpace: 'nowrap',
									fontSize: 10,
								}}>
								{item.Supplier === 'TBO'
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineName;
									  })
									: ''}
							</p>
							<p style={{ fontSize: 10 }}>
								{`${
									item.Supplier === 'TBO'
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Airline.AirlineCode;
										  })
										: ''
								}-${
									item.Supplier === 'TBO'
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Airline.FlightNumber;
										  })
										: ''
								}`}
							</p>
							<p
								title={item?.FareClassification?.Type}
								style={{
									background: item?.FareClassification?.Color,
									textAlign: 'center',
									borderRadius: 2,
									marginTop: 5,
									fontSize: 10,

									fontWeight: 'bold',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									width: '50px',

									// height: '1.2em',
									whiteSpace: 'nowrap',
									color:
										item?.FareClassification?.Type === 'Tactical'
											? 'white'
											: item?.FareClassification?.Type === 'Saver'
											? 'White'
											: 'black',
								}}>
								{item?.FareClassification?.Type}
							</p>
						</Box>
					</Box>
					{matches ? (
						''
					) : (
						<>
							<Box ml={2}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{item.Supplier
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Origin.DepTime.substr(11, 5);
										  })
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{item.Supplier === 'TBO' ? item.FareRules[0].Origin : ''}
								</p>
							</Box>
							<Box textAlign={'center'} alignItems={'center'}>
								<p style={{ fontSize: 10 }}>
									{timeConvert(
										item.Supplier === 'TBO'
											? item.Segments[0].length == 2
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: item.Segments[0].length == 1
												? item.Segments[0].map((i) =>
														timedifference(
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Origin.DepTime;
																}),
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Destination.ArrTime;
																})
														)
												  )
												: item.Segments[0].length == 3
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: ''
											: ''
									)}
								</p>
								<img src={Line} style={{ width: '50%', marginLeft: 40 }} />
								<p style={{ fontSize: 10 }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length === 1
											? 'Non-Stop'
											: item.Segments[0].length === 2
											? '1 Stop'
											: item.Segments[0].length === 3
											? '2 Stop'
											: ''
										: ''}
								</p>
							</Box>
							<Box mr={1}>
								<p
									style={{
										fontWeight: 'bold',
										fontSize: 10,
									}}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 1
											? item.Segments[0]
													.filter((i, index) => index === 0)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: ''
										: ''}
								</p>
								<p style={{ fontSize: 10 }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 1
											? item.Segments[0].map((it) => {
													return it.Destination.Airport.AirportCode;
											  })
											: ''
										: ''}
								</p>
							</Box>
						</>
					)}
					<Box>
						<Box ml={2}>
							<p
								style={{
									fontWeight: 'bold',
									fontSize: 18,
								}}>
								₹ 
								{item.Supplier === 'TBO'
									? Number(
											Math.round(
												Number(
													Number(Math.round(item.Fare.Total_Amount)) -
														Number(Math.round(item.Fare.MFB_Discount))
												)
											)
									  )
									: ''}
							</p>
							<p style={{ fontSize: 12 }}>
								{item.Supplier === 'TBO'
									? item.IsRefundable
										? 'Refundable'
										: 'Non-Refundable'
									: ''}
							</p>
						</Box>
						{/* <button
							onClick={() =>
								navigate('/flightreview', {
									state: {
										item: item,
										formData: location.state,
										traceId: tbo?.result?.Response.TraceId,
										orderId: orderId.result,
									},
								})
							}
							style={{
								backgroundColor: 'rgb(12, 163, 153)',
								padding: '8px 30px',
								color: 'white',
								borderRadius: 5,
								
								marginLeft: 5,
								fontSize: 10,
							}}>
							Book
						</button> */}
					</Box>
				</Box>
				{matches ? (
					<>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							style={{ padding: '0px 25px' }}>
							<Box>
								<p
									style={{
										fontWeight: 'bold',
									}}>
									{item.Supplier
										? item.Segments[0].slice(0, 1).map((i) => {
												return i.Origin.DepTime.substr(11, 5);
										  })
										: ''}
								</p>
								<p style={{ fontFamily: 'Outfit' }}>
									{item.Supplier === 'TBO' ? item.FareRules[0].Origin : ''}
								</p>
							</Box>
							<Box textAlign={'center'}>
								<p style={{ fontSize: 14 }}>
									{timeConvert(
										item.Supplier === 'TBO'
											? item.Segments[0].length == 2
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: item.Segments[0].length == 1
												? item.Segments[0].map((i) =>
														timedifference(
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Origin.DepTime;
																}),
															item.Segments[0]
																.filter((i, index) => index === 0)
																.map((it) => {
																	return it.Destination.ArrTime;
																})
														)
												  )
												: item.Segments[0].length == 3
												? timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Origin.DepTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 2)
															.map((it) => {
																return it.Destination.ArrTime;
															})
												  )
												: ''
											: ''
									)}
								</p>
								<img src={Line} />
								<p style={{ fontSize: 14 }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length === 1
											? 'Non-Stop'
											: item.Segments[0].length === 2
											? '1 Stop'
											: item.Segments[0].length === 3
											? '2 Stop'
											: ''
										: ''}
								</p>
							</Box>
							<Box>
								<p
									style={{
										fontWeight: 'bold',
									}}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: item.Segments[0].length == 1
											? item.Segments[0]
													.filter((i, index) => index === 0)
													.map((i) => {
														return i.Destination.ArrTime.substr(11, 5);
													})
											: ''
										: ''}
								</p>
								<p style={{ fontFamily: 'Outfit' }}>
									{item.Supplier === 'TBO'
										? item.Segments[0].length == 2
											? item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 3
											? item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.Airport.AirportCode;
													})
											: item.Segments[0].length == 1
											? item.Segments[0].map((it) => {
													return it.Destination.Airport.AirportCode;
											  })
											: ''
										: ''}
								</p>
							</Box>
						</Box>
					</>
				) : (
					''
				)}
				<Box
					onClick={() => {
						let form = {
							TraceId: tbo?.result?.Response.TraceId,
							ResultIndex: item.ResultIndex,
						};
						dispatch(TBOFlightFareRuleDispatch(form));
						handleData(item.ResultIndex);
					}}
					style={{
						backgroundColor: '#F8F8F8',
						padding: '10px 25px',
						cursor: 'pointer',
					}}>
					<p
						style={{
							color: '#006692',
							fontWeight: '500',

							fontSize: 10,
						}}>
						Flight Details
					</p>
				</Box>
				{data === item.ResultIndex ? (
					<>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							style={{ padding: 15 }}>
							<Box display={'flex'} alignItems={'center'}>
								<Box
									onClick={() => handleToggle('one')}
									style={{
										backgroundColor: toggle === 'one' ? 'rgb(12 163 153)' : '',
										padding: 10,
										borderRadius: 5,
										color: toggle === 'one' ? 'white' : '',

										fontSize: 10,
										cursor: 'pointer',
									}}>
									Flight Information
								</Box>
								<Box
									onClick={() => handleToggle('two')}
									style={{
										fontSize: 10,
										marginLeft: 10,
										backgroundColor: toggle === 'two' ? 'rgb(12 163 153)' : '',
										color: toggle === 'two' ? 'white' : '',
										padding: 10,
										borderRadius: 5,
										cursor: 'pointer',
									}}>
									Fare Details and Rules
								</Box>
							</Box>
							<Box onClick={() => handleData('')} style={{ cursor: 'pointer' }}>
								<img src={cross} />
							</Box>
						</Box>
						{toggle === 'one' &&
							item.Segments[0].map((i) => {
								return (
									<Box
										style={{
											padding: 15,
										}}>
										<Box
											style={{
												border: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderTopLeftRadius: 5,
												borderTopRightRadius: 5,
											}}>
											<p style={{ fontSize: 10 }}>
												{`${i.Origin.Airport.CityName} to ${
													i.Destination.Airport.CityName
												}, ${i.Origin.DepTime.split('T')[0]}`}
											</p>
										</Box>
										<Box
											style={{
												borderLeft: '1.33px solid rgba(203, 203, 203, 1)',
												borderRight: '1.33px solid rgba(203, 203, 203, 1)',
												borderBottom: '1.33px solid rgba(203, 203, 203, 1)',
												padding: 10,
												borderBottomLeftRadius: 5,
												borderBottomRightRadius: 5,
											}}>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={matches ? 'space-between' : ''}>
												<Box display={'flex'} alignItems={'center'}>
													<img
														src={`https://nitish.musafirbazar.com/static/media/${i.Airline.AirlineCode}.gif`}
														style={{ borderRadius: 5, height: 45 }}
													/>
													<Box ml={2}>
														<p
															style={{
																fontWeight: 'bold',
																fontSize: 10,
															}}>
															{i.Airline.AirlineName}
														</p>
														<p style={{ fontSize: 10 }}>
															{`${i.Airline.AirlineCode}-${i.Airline.FlightNumber}`}
														</p>
													</Box>
												</Box>
												{matches ? (
													<Box display={'flex'}>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Check in
															</p>
															<p style={{ fontSize: 10 }}>{i.Baggage}</p>
														</Box>
														<Box ml={2}>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Cabin
															</p>
															<p style={{ fontSize: 10 }}>{i.CabinBaggage}</p>
														</Box>
													</Box>
												) : (
													''
												)}
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												mt={2}>
												<Box>
													<p
														style={{
															fontWeight: 'bold',
															fontSize: 10,
														}}>
														{i.Origin.DepTime.substr(11, 5)}
													</p>
													<p style={{ fontSize: 10 }}>
														{i.Origin.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 10 }}>{i.Origin.DepTime}</p>
													<p style={{ fontSize: 10 }}>
														{`Terminal ${i.Origin.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 10 }}>
														{i.Origin.Airport.CityName},{' '}
														{i.Origin.Airport.CountryName}
													</p>
												</Box>
												<Box textAlign={'center'}>
													<p style={{ fontSize: 10 }}>
														{timeConvert(
															timedifference(
																i.Origin.DepTime,
																i.Destination.ArrTime
															)
														)}
													</p>
													<img src={Line} />
													<p style={{ fontSize: 10 }}>Non-Stop</p>
												</Box>
												<Box style={{ textAlign: matches ? 'end' : '' }}>
													<p
														style={{
															fontWeight: 'bold',
															fontSize: 10,
														}}>
														{i.Destination.ArrTime.substr(11, 5)}
													</p>
													<p style={{ fontSize: 10 }}>
														{i.Destination.Airport.AirportCode}
													</p>
													<p style={{ fontSize: 10 }}>
														{i.Destination.ArrTime}
													</p>
													<p style={{ fontSize: 10 }}>
														{`Terminal ${i.Destination.Airport.Terminal}`}
													</p>
													<p style={{ fontSize: 10 }}>
														{i.Destination.Airport.CityName},{' '}
														{i.Destination.Airport.CountryName}
													</p>
												</Box>
												{matches ? (
													''
												) : (
													<>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Baggage
															</p>
															<p style={{ fontSize: 10 }}>Adult</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Check in
															</p>
															<p style={{ fontSize: 10 }}>{i.Baggage}</p>
														</Box>
														<Box>
															<p
																style={{
																	fontWeight: 'bold',
																	fontSize: 10,
																}}>
																Cabin
															</p>
															<p style={{ fontSize: 10 }}>{i.CabinBaggage}</p>
														</Box>
													</>
												)}
											</Box>
										</Box>
									</Box>
								);
							})}
						{toggle === 'two' && (
							<>
								{loadingRule ? (
									<p style={{ textAlign: 'center', marginBottom: 10 }}>
										Please wait...
									</p>
								) : (
									<div
										style={{ fontSize: 14, marginTop: 20, padding: 20 }}
										dangerouslySetInnerHTML={{
											__html:
												fareRule?.result?.Response?.FareRules[0]
													?.FareRuleDetail,
										}}
									/>
								)}
							</>
						)}
					</>
				) : (
					''
				)}
			</Box>
		</>
	);
};

export default FlightRoundTripRowReturn;
