import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import logo from '../assets/images/logo.png';

const DomesticAirlinePage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					Domestic Airline
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<p>
							The domestic airline market in India is the world s third largest,
							and it is likely to overtake the top spot in the next 10 to 15
							years. Much of this can be attributed to India s local airlines
							becoming more competitive. Local airlines have continually given
							affordable fares for air travel over the last few years while
							maintaining and upgrading their services. As a result, an
							increasing number of clients are opting for air travel each year.
							But, with so many options, how can you pick the finest flying
							experience? We ve compiled a list of some of India s greatest
							airlines to assist you can consider for your next flight booking.
						</p>
						<br />
						<p>
							Check out India s most popular domestic flights. On
							MarinersForex.com, you may check the status of your PNR and check
							in through the Web.
						</p>
						<table style={{ width: '100%', marginTop: 20 }}>
							<tbody>
								{Array.from(Array(20)).map((i) => {
									return (
										<>
											<tr>
												<td style={{ padding: 10 }}>Air India</td>
												<td
													style={{
														textAlign: 'center',
														padding: 10,
													}}>
													{' '}
													PNR Status on Air India
												</td>
												<td
													style={{
														textAlign: 'center',
														padding: 10,
													}}>
													Flights Web Check-In Air India
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
						<br />
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default DomesticAirlinePage;
