import HomeReducer from './HomeReducer';
import UiReducer from './UiReducer';
import TBOReducer from './TBOReducer';
import HotelReducer from './HotelReducer';
import AgentReducer from './AgentReducer';
import BusReducer from './BusReducer';
const rootReducer = {
	ui: UiReducer.reducer,
	home: HomeReducer.reducer,
	tbo: TBOReducer.reducer,
	hoteltbo: HotelReducer.reducer,
	agent: AgentReducer.reducer,
	bus: BusReducer.reducer,
};

export default rootReducer;
