import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showFootermenuDispatch } from '../reducers/HomeReducer';

const AboutUsPage = () => {
	const dispatch = useDispatch();
	const { footerMenu } = useSelector((state) => state.home);
	let cont = [];
	if (footerMenu?.result?.length > 0) {
		footerMenu?.result?.map((i) => {
			if (i.id === '140') {
				cont.push({
					...i,
				});
			}
		});
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(showFootermenuDispatch());
	}, []);
	return (
		<>
			<Navbar />
			<Box
				style={{
					backgroundImage: ` linear-gradient(
		to right,
		#041b28 39.64%,
		rgba(2, 15, 22, 0.548011) 75.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
					height: 150,
				}}>
				<br />
				<br />
				<h1 style={{ color: 'white', fontSize: 36, textAlign: 'center' }}>
					About Us
				</h1>
			</Box>
			<Container>
				<Grid container style={{ marginTop: 50 }} alignItems={'center'}>
					<Grid item xs={12}>
						<div
							style={{ marginTop: 20 }}
							dangerouslySetInnerHTML={{
								__html: cont[0]?.content,
							}}
						/>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</>
	);
};

export default AboutUsPage;
