import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { agentloginDispatch } from '../reducers/AgentReducer';
import { useNavigate } from 'react-router-dom';
const SignInSchema = Yup.object().shape({
	email: Yup.string().required('Email Required'),
	password: Yup.string().required('Password Required'),
});
const LoginForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleFormSubmit = (data) => {
		// alert(JSON.stringify(data));
		let form = {
			username: data.email,
			password: data.password,
		};
		// alert(JSON.stringify(form));
		dispatch(agentloginDispatch(form, handleClose, navigate, setOpen));
	};
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 10 }} spacing={4}>
											<Grid item xs={12}>
												<span
													style={{
														color: 'rgba(8, 30, 42, 1)',
														fontWeight: 'bold',
													}}>
													Email
												</span>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your email*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<Box display={'flex'} justifyContent={'space-between'}>
													<span
														style={{
															color: 'rgba(8, 30, 42, 1)',
															fontWeight: 'bold',
														}}>
														Your password
													</span>
													<span
														onClick={() => SetStatus('forgotpassword')}
														style={{
															color: 'rgba(243, 32, 32, 1)',
															fontSize: 12,
															cursor: 'pointer',
														}}>
														Forgot Password?
													</span>
												</Box>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid rgba(0, 0, 0, 0.08)',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>
											{/* <Grid item xs={12}>
												<span>or</span>
												<Box display={'flex'} ml={5}>
													<Box display={'flex'}>
														<img src={google} />
														<p style={{ fontSize: 12, marginLeft: 5 }}>
															Sign in with Google
														</p>
													</Box>
													<Box display={'flex'} ml={5}>
														<img src={fb} />
														<p style={{ fontSize: 12, marginLeft: 5 }}>
															Sign in with facebook
														</p>
													</Box>
												</Box>
											</Grid> */}
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: 'rgb(12, 163, 153)',
														color: 'white',
														width: '100%',
														padding: 10,
													}}>
													Sign in
												</Button>
												<p style={{ fontSize: 12, marginTop: 15 }}>
													{/* By signing up, you agree to Customers.ai’s Terms Of
													Service and Privacy Policy */}
												</p>
												<Box display={'flex'} alignItems={'center'} mt={2}>
													<p style={{ fontSize: 12 }}>
														Don,t you have an account?
													</p>
													<Button
														onClick={() => SetStatus('register')}
														style={{
															backgroundColor: 'rgb(12, 163, 153)',
															color: 'white',
															textTransform: 'capitalize',
															marginLeft: 10,
															padding: '4px 20px',
															fontSize: 12,
														}}>
														Register
													</Button>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default LoginForm;
